import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material"
import CustomInput from "component/CustomInput"
import CustomTabPanel from "component/CustomTabPanel"
import { Status, ScanResponse } from "generated/graphql"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import LabellingInfo from "./LabellingInfo"
import LabellingOptions from "./LabellingOptions"
import LabellingScanHistory from "./LabellingScanHistory"
import LabellingStatus from "./LabellingStatus"
import { orderItemDistribute } from "queries/orderItemDistribute"
import { removeFirstChar } from "utils/removeFirstChar"
import { isChar } from "utils/isChar"
import { enqueueSnackbar } from "notistack"
import { speech, Speech } from "utils/speech"

const DistributePage = (): JSX.Element => {
  const nav = useNavigate()
  const [barcode, setBarcode] = useState<string>("")
  const [barcode2, setBarcode2] = useState<string>("")
  const [value, setValue] = useState(0)
  const [scanRes, setScanRes] = useState<ScanResponse | null>(null)
  const [status, setStatus] = useState<string>("")
  const [background, setBackground] = useState("")
  const [isOrderNumberScan, setIsOrderNumberScan] = useState(false)
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [barcode])

  const setOptions = (newPmLocation: string, newIsPalletArticle: boolean) => {
    if (
      scanRes != null &&
      scanRes.orderDetails != null &&
      scanRes.scannedArticle != null
    ) {
      const newScannedArticle = {
        ...scanRes.scannedArticle,
        isPalletArticle: newIsPalletArticle,
        pmLocation: newPmLocation,
      }

      const newItems = scanRes.orderDetails.items.map((value: any) => {
        if (scanRes.scannedArticle != null) {
          if (value.id === scanRes.scannedArticle.id) {
            return newScannedArticle
          }
        }
        return value
      })

      setScanRes((value: any) => ({
        ...value,
        scannedArticle: newScannedArticle,
        orderDetails: { ...value.orderDetails, items: newItems },
      }))
    }
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const getOrderDetails = async (barcode: string) => {
    const prefix = barcode[0].toLowerCase()
    if (isOrderNumberScan) {
      if (prefix !== "o") {
        enqueueSnackbar(t("wrongBarcode"), { variant: "error" })
        return
      }
      if (isChar(barcode[0])) {
        barcode = removeFirstChar(barcode)
      }
      if (scanRes?.orderDetails != null) {
        // check if the order number is correct
        // the scanned orderID can be with or without prefix
        // remove the last 1 digit of barcode ( it is the check digit )
        // the response from the server is without the check digit so we remove it
        // and compare it to the orderID
        barcode = barcode.substring(0, barcode.length - 1)
        if (barcode !== scanRes.orderDetails.orderID.toString()) {
          setBackground(theme.palette.error.light)
          setStatus(t("wrong box"))
          return
        }
      }
      if (scanRes?.scannedArticle != null) {
        const res = await orderItemDistribute({
          barcode: scanRes?.scannedArticle.id.toString(),
          hasSuffixStep1: false,
        })
        if (res?.orderItemDistribute) {
          setScanRes({ ...res.orderItemDistribute })
          setStatus(t(res.orderItemDistribute.status))
          switch (res.orderItemDistribute.status) {
            case Status.BarcodeInvalid:
            case Status.Stop:
            case Status.AlreadyDistributed:
              setBackground(theme.palette.error.light)
              speech(Speech.Stop)
              break
            case Status.OrderComplete:
            case Status.Success:
              setBackground(theme.palette.success.light)
              speech(Speech.Ok)
              setIsOrderNumberScan(false)
              break
            default:
              setBackground(theme.palette.background.default)
          }
        }
      }
    } else {
      if (isChar(barcode[0])) {
        barcode = removeFirstChar(barcode)
      }
      const res = await orderItemDistribute({
        barcode: barcode,
        hasSuffixStep1: true,
      })
      if (res?.orderItemDistribute) {
        setScanRes(res.orderItemDistribute)
        setStatus(t(res.orderItemDistribute.status))
        switch (res.orderItemDistribute.status) {
          case Status.BarcodeInvalid:
          case Status.Stop:
          case Status.AlreadyDistributed:
          case Status.PlantSplitted:
            setBackground(theme.palette.error.light)
            speech(Speech.Stop)
            break
          case Status.OrderComplete:
            setBackground(theme.palette.success.light)
            setStatus(t("Scan Ordernumber"))
            setIsOrderNumberScan(true)
            speech(Speech.BoxDone)
            break
          case Status.Success:
            setBackground(theme.palette.success.light)
            setStatus(t("Scan Ordernumber"))
            setIsOrderNumberScan(true)
            break
          default:
            setBackground(theme.palette.background.default)
        }
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getOrderDetails(barcode)
      setBarcode2(barcode)
      setBarcode("")
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("distribute")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="barcode"
            placeholder="Barcode"
            data-cy="barcodeInput"
            inputRef={barcodeInputRef}
            value={barcode}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>

      {scanRes && (
        <Box
          sx={{
            paddingTop: "1%",
            paddingRight: "1%",
            paddingLeft: "1%",
          }}
        >
          <Card>
            <CardContent>
              <Typography sx={{ textAlign: "center" }} variant="h6">
                {status}
              </Typography>
            </CardContent>
          </Card>

          <Box
            sx={{
              paddingTop: 1,
              display: "flex",
              flex: 10,
              flexGrow: 1,
            }}
          >
            <CustomTabPanel value={value} index={0}>
              <LabellingInfo
                orderDetails={scanRes.orderDetails}
                scannedArticle={scanRes.scannedArticle}
                items={
                  scanRes.orderDetails?.items?.filter((v) => {
                    return (
                      !v.distributed &&
                      !v.isSplitted &&
                      v.id !== scanRes.scannedArticle?.id
                    )
                  }) || []
                }
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {scanRes.orderDetails && (
                <LabellingStatus
                  orderDetails={scanRes.orderDetails}
                  setTagRes={setScanRes}
                  height={392}
                />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {scanRes.scannedArticle && (
                <LabellingOptions
                  scannedArticle={scanRes.scannedArticle}
                  barcode={barcode2}
                  setOptions={setOptions}
                />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <LabellingScanHistory />
            </CustomTabPanel>
          </Box>

          <Tabs
            value={value}
            variant="fullWidth"
            centered
            sx={{
              backgroundColor: "white",
              zIndex: 1000,
            }}
            onChange={handleChange}
          >
            <Tab label={t("info")} data-cy="tabInfo" id="0" />
            <Tab label={t("status")} data-cy="tabStatus" id="1" />
            <Tab label={t("options")} data-cy="tabOptions" id="2" />
            <Tab label={t("scanHistory")} data-cy="tabHistory" id="3" />
          </Tabs>
        </Box>
      )}
    </Box>
  )
}

export default DistributePage
