import { fetchGQL } from "app/api"
import {
  IsOrderShipableQuery,
  IsOrderShipableQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const IS_ORDER_SHIPABLE = gql`
  query isOrderShipable($oid: Int!) {
    isOrderShipable(oid: $oid) {
      msg
      IsShipable
    }
  }
`

export const fetchIsOrderShipable = async (
  oid: number
): Promise<IsOrderShipableQuery | undefined> => {
  const variables: IsOrderShipableQueryVariables = { oid }
  const data = await fetchGQL<
    IsOrderShipableQuery,
    IsOrderShipableQueryVariables
  >(IS_ORDER_SHIPABLE, variables)

  return data
}
