export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Address = {
  __typename?: 'Address';
  anrede: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AddressInput = {
  anrede: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  country: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  customerID: Scalars['Int']['input'];
  firstName: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type Article = {
  __typename?: 'Article';
  artNum: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  currentPrice: Scalars['Float']['output'];
  distributed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPalletArticle: Scalars['Boolean']['output'];
  isSplitted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  personDistributed: Scalars['String']['output'];
  personTagged: Scalars['String']['output'];
  pmLocation: Scalars['String']['output'];
  size: Scalars['String']['output'];
  supplier: Scalars['String']['output'];
  tagged: Scalars['Boolean']['output'];
  /** in this format: 24.04.2021 09:35 */
  timeDistributed: Scalars['String']['output'];
  /** in this format: 24.04.2021 09:35 */
  timeTagged: Scalars['String']['output'];
};

export type ArticleChangelogItem = {
  __typename?: 'ArticleChangelogItem';
  action: Scalars['String']['output'];
  bdb: Scalars['String']['output'];
  changeDate: Scalars['String']['output'];
  computerName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  userName: Scalars['String']['output'];
};

export type ArticleDetail = {
  __typename?: 'ArticleDetail';
  articleGroup: Scalars['Int']['output'];
  availableFrom: Scalars['String']['output'];
  bdb: Scalars['String']['output'];
  botname: Scalars['String']['output'];
  customerContainerSize: Scalars['String']['output'];
  gername: Scalars['String']['output'];
  h24: Scalars['String']['output'];
  localStock: Scalars['String']['output'];
  location: Scalars['String']['output'];
  pflNR: Scalars['String']['output'];
  plantTypes: Array<PlantType>;
  price: Scalars['String']['output'];
  quali: Scalars['String']['output'];
  reducedPrice: Scalars['String']['output'];
  reducedPriceUntil: Scalars['String']['output'];
  saleMinCount: Scalars['String']['output'];
  salePct: Scalars['String']['output'];
  sizeFrom: Scalars['String']['output'];
  sizeTo: Scalars['String']['output'];
  sorte: Scalars['String']['output'];
  statusAfterSoldOut: Scalars['String']['output'];
  stock: Scalars['String']['output'];
  supplierAfterSoldOut: Scalars['Int']['output'];
  supplierContainerSize: Scalars['String']['output'];
  trunkHeight: Scalars['String']['output'];
  trunkSizeFrom: Scalars['String']['output'];
  trunkSizeTo: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type ArticleDetailsInput = {
  articleGroup: Scalars['Int']['input'];
  availableFrom: Scalars['String']['input'];
  bdb: Scalars['String']['input'];
  botname: Scalars['String']['input'];
  customerContainerSize: Scalars['String']['input'];
  gername: Scalars['String']['input'];
  h24: Scalars['String']['input'];
  localStock: Scalars['Int']['input'];
  location: Scalars['String']['input'];
  pfNr: Scalars['String']['input'];
  price: Scalars['String']['input'];
  quali: Scalars['String']['input'];
  reducedPrice: Scalars['String']['input'];
  reducedPriceUntil: Scalars['String']['input'];
  saleMinCount: Scalars['String']['input'];
  salePct: Scalars['String']['input'];
  sizeFrom: Scalars['String']['input'];
  sizeTo: Scalars['String']['input'];
  sorte: Scalars['String']['input'];
  statusAfterSoldOut: Scalars['String']['input'];
  stock: Scalars['Int']['input'];
  supplierAfterSoldOut: Scalars['Int']['input'];
  supplierContainerSize: Scalars['String']['input'];
  trunkHeight: Scalars['String']['input'];
  trunkSizeFrom: Scalars['String']['input'];
  trunkSizeTo: Scalars['String']['input'];
  variant: Scalars['String']['input'];
};

export type ArticleHistory = {
  __typename?: 'ArticleHistory';
  articleName: Scalars['String']['output'];
  finishedAt: Scalars['String']['output'];
  from: Scalars['Int']['output'];
  orderID: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  round: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  taskType: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
};

export type ArticleInventoryReport = {
  __typename?: 'ArticleInventoryReport';
  bestellcode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  pmbdb: Scalars['String']['output'];
  price: Scalars['String']['output'];
  quality: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  realbdb: Scalars['String']['output'];
  saugtxt: Scalars['String']['output'];
  sticky: Scalars['Boolean']['output'];
  supplier: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
};

export type ArticleItem = {
  __typename?: 'ArticleItem';
  bdb: Scalars['String']['output'];
  botname: Scalars['String']['output'];
  gername: Scalars['String']['output'];
  lform: Scalars['String']['output'];
  pflNR: Scalars['String']['output'];
  price: Scalars['String']['output'];
  quali: Scalars['String']['output'];
  reducedPrice: Scalars['String']['output'];
  sizeFrom: Scalars['Int']['output'];
  sizeTo: Scalars['Int']['output'];
  sorte: Scalars['String']['output'];
};

export type ArticleNewInput = {
  newPrice: Scalars['Float']['input'];
  position: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  sourceArticleBDB: Scalars['String']['input'];
};

export type ArticlePositionInput = {
  barcode: Scalars['String']['input'];
  position: Scalars['String']['input'];
  supplier: Scalars['String']['input'];
};

export type ArticlePriceHistory = {
  __typename?: 'ArticlePriceHistory';
  action: Scalars['String']['output'];
  bdb: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type ArticleSplitInput = {
  articleIds: Array<Scalars['Int']['input']>;
};

export type ArticleSplitResponse = {
  __typename?: 'ArticleSplitResponse';
  message?: Maybe<Scalars['String']['output']>;
  newOrderDetails?: Maybe<Order>;
  orderDetails?: Maybe<Order>;
  statusCode: Scalars['Int']['output'];
};

export type ArticleStoragePlaceInput = {
  eanBarcode: Scalars['String']['input'];
  storagePlace: Scalars['String']['input'];
};

export type ArticleSupplier = {
  __typename?: 'ArticleSupplier';
  articleID: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  note: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  quality: Array<Scalars['String']['output']>;
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ArticleSupplierCrossRef = {
  __typename?: 'ArticleSupplierCrossRef';
  articleID: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type ArticleSupplierInput = {
  articleID: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  note: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  priority: Scalars['Int']['input'];
  quality: Array<Scalars['String']['input']>;
  supplierID: Scalars['Int']['input'];
  supplierName: Scalars['String']['input'];
};

export type AskDanteTimeTracking = {
  __typename?: 'AskDanteTimeTracking';
  Duration: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  /** an askdante users time tracking for a given day */
  userId: Scalars['String']['output'];
};

export type AskDanteUser = {
  __typename?: 'AskDanteUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** an askDante User */
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  personnelNumber: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
};

export type AuthRequest = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/**
 * This is a description of the AuthResponse type.
 * It's used for returning the user and the token after a successful login.
 */
export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: User;
};

export enum Carrier {
  Dhl = 'DHL',
  DhlWarenpost = 'DHL_WARENPOST',
  Dpd = 'DPD',
  Gls = 'GLS',
  GlsExpress = 'GLS_EXPRESS',
  GlsLetterbox = 'GLS_LETTERBOX'
}

export type Carton = {
  __typename?: 'Carton';
  barcode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  heightMM: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lengthMM: Scalars['Int']['output'];
  widthMM: Scalars['Int']['output'];
};

export type CartonInput = {
  barcode: Scalars['String']['input'];
  description: Scalars['String']['input'];
  heightMM: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  lengthMM: Scalars['Int']['input'];
  widthMM: Scalars['Int']['input'];
};

export type CartonStatistic = {
  __typename?: 'CartonStatistic';
  carton: Carton;
  count: Scalars['Int']['output'];
  countPercent: Scalars['Float']['output'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type CategoryFilterInput = {
  activeOnly: Scalars['Boolean']['input'];
};

export type Coupon = {
  __typename?: 'Coupon';
  article_id?: Maybe<Scalars['String']['output']>;
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  combinable: Scalars['Boolean']['output'];
  created_on: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discount: Scalars['Int']['output'];
  editable: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  max_uses?: Maybe<Scalars['Int']['output']>;
  min_sum: Scalars['Int']['output'];
  no_delivery_costs: Scalars['Boolean']['output'];
  note_intern: Scalars['String']['output'];
  product_id?: Maybe<Scalars['String']['output']>;
  sale_allowed: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  valid_from?: Maybe<Scalars['String']['output']>;
  valid_until?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
  who_created: Scalars['String']['output'];
};

export type CouponByIdInput = {
  excludeSov: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
};

export type CouponCreateInput = {
  article_id?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  combinable: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  discount: Scalars['Int']['input'];
  key: Scalars['String']['input'];
  max_uses?: InputMaybe<Scalars['Int']['input']>;
  min_sum: Scalars['Int']['input'];
  no_delivery_costs: Scalars['Boolean']['input'];
  note_intern: Scalars['String']['input'];
  product_id?: InputMaybe<Scalars['String']['input']>;
  sale_allowed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  valid_from?: InputMaybe<Scalars['String']['input']>;
  valid_until?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};

export type CouponDeleteInput = {
  id: Array<Scalars['Int']['input']>;
};

export type CouponUpdateInput = {
  article_id?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  combinable: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  discount: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  key: Scalars['String']['input'];
  max_uses?: InputMaybe<Scalars['Int']['input']>;
  min_sum: Scalars['Int']['input'];
  no_delivery_costs: Scalars['Boolean']['input'];
  note_intern: Scalars['String']['input'];
  product_id?: InputMaybe<Scalars['String']['input']>;
  sale_allowed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  valid_from?: InputMaybe<Scalars['String']['input']>;
  valid_until?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};

export type CouponsByActiveDateInput = {
  activeDate: Scalars['String']['input'];
  excludeSov: Scalars['Boolean']['input'];
};

export type CouponsByKeyInput = {
  excludeSov: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
};

export type CustomerDebit = {
  __typename?: 'CustomerDebit';
  customerId: Scalars['Int']['output'];
  datum: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_admin: Scalars['String']['output'];
  note: Scalars['String']['output'];
  orderId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type CustomerDebitInput = {
  customerId: Scalars['Int']['input'];
  note: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type CustomerDebitRequest = {
  customerId: Scalars['Int']['input'];
};

export type CustomerDetail = {
  __typename?: 'CustomerDetail';
  anrede: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  country: Scalars['String']['output'];
  custnote: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  handy: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inbase: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  nickName: Scalars['String']['output'];
  rechName: Scalars['String']['output'];
  rechOrt: Scalars['String']['output'];
  rechPlz: Scalars['String']['output'];
  rechStreet: Scalars['String']['output'];
  street: Scalars['String']['output'];
  telefon: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CustomerDetailInput = {
  anrede: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  country: Scalars['String']['input'];
  custnote: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  handy: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  inbase: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nickName: Scalars['String']['input'];
  rechName: Scalars['String']['input'];
  rechOrt: Scalars['String']['input'];
  rechPlz: Scalars['String']['input'];
  rechStreet: Scalars['String']['input'];
  street: Scalars['String']['input'];
  telefon: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  bezahlt: Scalars['Float']['output'];
  custnote: Scalars['String']['output'];
  datum: Scalars['String']['output'];
  frachtpausch: Scalars['Float']['output'];
  notiz: Scalars['String']['output'];
  orderID: Scalars['Int']['output'];
  rechnum: Scalars['Int']['output'];
  rechsum: Scalars['Float']['output'];
  status: Scalars['Int']['output'];
  urorder: Scalars['Int']['output'];
  zahlart: Scalars['String']['output'];
  zieldat: Scalars['String']['output'];
};

export type CustomerOrderInput = {
  customerID: Scalars['Int']['input'];
  deliveryAdrId: Scalars['Int']['input'];
  orderItems: Array<CustomerOrderItemInput>;
  status: Scalars['Int']['input'];
  zahlart: Scalars['String']['input'];
};

export type CustomerOrderItemInput = {
  anzahl: Scalars['Int']['input'];
  bdb: Scalars['Int']['input'];
  stueckpreis: Scalars['Int']['input'];
};

export type CustomerRefund = {
  __typename?: 'CustomerRefund';
  amount: Scalars['Int']['output'];
  articleID?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  customerID: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  orderID: Scalars['Int']['output'];
  settledAt?: Maybe<Scalars['String']['output']>;
  settledBy?: Maybe<Scalars['String']['output']>;
};

export type CustomerRefundCreateInput = {
  amount: Scalars['Int']['input'];
  articleID?: InputMaybe<Scalars['Int']['input']>;
  customerID: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  orderID: Scalars['Int']['input'];
};

export type CustomerRefundInput = {
  from: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  onlySettled: Scalars['Boolean']['input'];
  to: Scalars['String']['input'];
};

export type CustomerRefundResponse = {
  __typename?: 'CustomerRefundResponse';
  rows: Array<CustomerRefund>;
  totalRows: Scalars['Int']['output'];
};

export type CustomerRefundSettleInput = {
  id: Scalars['Int']['input'];
};

export type CustomerSearchInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  inbase: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nickName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CustomerSearchRes = {
  __typename?: 'CustomerSearchRes';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  handy: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  nickName: Scalars['String']['output'];
  telefon: Scalars['String']['output'];
};

export type EmployeeStats = {
  __typename?: 'EmployeeStats';
  askDanteFirstName: Scalars['String']['output'];
  /** contains all a employees statistics */
  askDanteId: Scalars['String']['output'];
  askDanteLastName: Scalars['String']['output'];
  askDanteRole: Scalars['String']['output'];
  chatAssignedCount: Scalars['Int']['output'];
  chatMessagesCount: Scalars['Int']['output'];
  chatRatings: Array<RatingValue>;
  chatResolvedCount: Scalars['Int']['output'];
  chatUniqueResolvedCount: Scalars['Int']['output'];
  closedCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  freshdeskId: Scalars['String']['output'];
  freshdeskName: Scalars['String']['output'];
  ratings: Array<FreshdeskRating>;
  resolvedCount: Scalars['Int']['output'];
  statsHistory: Array<EmployeeStatsDay>;
  tags: Array<Scalars['String']['output']>;
  tickets: Array<FreshdeskTicket>;
  workedCount: Scalars['Int']['output'];
};

export type EmployeeStatsDay = {
  __typename?: 'EmployeeStatsDay';
  conversations: Array<FreshdeskConversation>;
  /** Contains employee stats for a specific day */
  date: Scalars['String']['output'];
  timeTrackingDuration: Scalars['Int']['output'];
};

export type EmployeeStatsInput = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type EmployeeStatsOutput = {
  __typename?: 'EmployeeStatsOutput';
  lastUpdate: Scalars['String']['output'];
  stats: Array<EmployeeStats>;
};

export type EtikettenData = {
  __typename?: 'EtikettenData';
  bdb: Scalars['Int']['output'];
  bis: Scalars['Int']['output'];
  botname: Scalars['String']['output'];
  etiNum: Scalars['Int']['output'];
  ewid: Scalars['Int']['output'];
  orderID: Scalars['Int']['output'];
  posten: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  sorte: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
  totalBasketItems: Scalars['Int']['output'];
  von: Scalars['Int']['output'];
};

export type FileInput = {
  Filename: Scalars['String']['input'];
  Value: Scalars['String']['input'];
};

export type FilterTemplate = {
  __typename?: 'FilterTemplate';
  name: Scalars['String']['output'];
  template: Scalars['String']['output'];
};

export type FreshdeskAgent = {
  __typename?: 'FreshdeskAgent';
  email: Scalars['String']['output'];
  /** a freshdesk agent */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FreshdeskConversation = {
  __typename?: 'FreshdeskConversation';
  agentId: Scalars['String']['output'];
  category: Scalars['Int']['output'];
  incoming: Scalars['Boolean']['output'];
  isEmail: Scalars['Boolean']['output'];
  private: Scalars['Boolean']['output'];
  source: Scalars['Int']['output'];
  /** a freshdesk conversation */
  ticketId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FreshdeskRating = {
  __typename?: 'FreshdeskRating';
  rating: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FreshdeskTicket = {
  __typename?: 'FreshdeskTicket';
  agentId: Scalars['Int']['output'];
  group: Scalars['String']['output'];
  /** a freshdesk ticket */
  id: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FreshdeskTicketInput = {
  description: Scalars['String']['input'];
  files: Array<FileInput>;
  selectedSubject: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Libra = {
  __typename?: 'Libra';
  id: Scalars['Int']['output'];
  ip: Scalars['String']['output'];
  port: Scalars['String']['output'];
};

/** represents a logistic statistic of the system. */
export type LogisticStat = {
  __typename?: 'LogisticStat';
  distributed: Scalars['Int']['output'];
  packagesSent: Scalars['Int']['output'];
  tagged: Scalars['Int']['output'];
};

/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type Mutation = {
  __typename?: 'Mutation';
  addCustomerDebit: Scalars['Boolean']['output'];
  /** articleDetailsSave saves the details of an article */
  articleDetailsSave: Scalars['Boolean']['output'];
  /**
   * articleNew creates a new article
   * this procedure is for left over articles that needs to be pushed into the store
   * at a reduced price to get rid of them
   */
  articleNew: Scalars['Boolean']['output'];
  articlePosition: Scalars['Int']['output'];
  /** articleSplit splits an order */
  articleSplit: ArticleSplitResponse;
  /**
   * articleSupplierDelete deletes the supplied articleSupplier entry
   * returns true on successful deletion, otherwise false
   */
  articleSupplierDelete: Scalars['Boolean']['output'];
  /**
   * articleSupplierSave saves a supplier for a specific article
   * IF id == 0
   *   create a new supplier for the article
   * ELSE
   *   update the supplier for the article
   */
  articleSupplierSave: ArticleSupplier;
  /**
   * articleSupplierSwapPriority swaps the priority of two suppliers
   * id1 and id2 will be swapped like this:
   * id1 -> priority of id2
   * id2 -> priority of id1
   */
  articleSupplierSwapPriority: Scalars['Boolean']['output'];
  /** articleUpdateStorageplace updates the storage place of an article */
  articleUpdateStorageplace: Scalars['Boolean']['output'];
  /** Create a new box */
  boxCreate: Carton;
  /** Delete a existing box */
  boxDelete: Scalars['Boolean']['output'];
  /** Update a existing box */
  boxUpdate: Carton;
  /** coupon create */
  couponCreate: Coupon;
  /** coupon delete */
  couponDelete: Scalars['Boolean']['output'];
  /** coupon update */
  couponUpdate: Coupon;
  /** create a rs logistic shipment */
  createRsShipment: RsShipmentResponse;
  /** save customer delivery address */
  customerAddressSave: Address;
  /** save customer Details (single) */
  customerDetailsSave: CustomerDetail;
  /** create order for customer */
  customerOrderCreate: CustomerOrder;
  /** customerRefund create */
  customerRefundCreate: CustomerRefund;
  /** customerRefund settle */
  customerRefundSettle: CustomerRefund;
  /** delete filter template */
  deleteFilterTemplate: Scalars['Boolean']['output'];
  /**
   * delete plant type
   * safe delete if there is an entry with this plant type id it won't be deleted
   */
  deletePlantType: Scalars['String']['output'];
  editAskDanteUserTags: Scalars['Boolean']['output'];
  /** Create a ticket for freshdesk */
  freshdeskTicketCreate: Scalars['Boolean']['output'];
  /** orderCancel cancel an order */
  orderCancel: Scalars['Boolean']['output'];
  /** orderChange changes specific order details */
  orderChange: Scalars['Boolean']['output'];
  /** orderItemDistribute */
  orderItemDistribute: ScanResponse;
  /**
   * orderItemReset
   * reset the state of a item that has already been tagged or distributed
   * warning will reset also the people who tagged / distributed / packed it
   */
  orderItemReset: ScanResponse;
  /** orderItemsCancel cancel some or all order items */
  orderItemsCancel: Scalars['Boolean']['output'];
  /** orderSplit split an order */
  orderSplit: Scalars['String']['output'];
  /** set a package error to resolved. */
  packErrorResolve: Scalars['Boolean']['output'];
  /** rabenShipmentCreate */
  rabenShipmentCreate: RabenShipmentResponse;
  /** rmlShipmentCreate */
  rmlShipmentCreate: Scalars['String']['output'];
  /**
   * @in: create a round with the details
   * @res: create a round
   */
  roundCreate: RoundCreateResponse;
  /**
   * roundItemEditDescription
   * will edit the description of a supplier order item
   * @in: the details of the edited article
   */
  roundItemEditDescription: Scalars['Boolean']['output'];
  /**
   * roundItemsAdd
   * will create a storage order for the given article and put it on the supplier order list
   * @in: articles to be added to the current round and supplier
   */
  roundItemsAdd: Scalars['Boolean']['output'];
  /**
   * roundItemsEditQuantity
   * will create a new or edit an existing storage orders for the given article and update the supplier order list
   * @in: the details of the edited article
   */
  roundItemsEdit: Scalars['Boolean']['output'];
  /**
   * roundItemsSplit split round positions into new orders
   * the orders will be split into 'nachlieferungen' and the order position will be deleted from the roundlist for that supplier
   * @roundListIds = the id of the item to be moved to a new order
   * @statusCode = the next Status for the new order
   * @mergeStatuses = the status for other orders that should be summed up in one order
   */
  roundItemsSplit: RoundItemsSplitResponse;
  /** save plant types for an article */
  saveArticlePlantTypes: Scalars['Boolean']['output'];
  /** save filter template */
  saveFilterTemplate: Scalars['Boolean']['output'];
  /**
   * save new plant type
   * create new plant type or update existing one
   * given id is for update
   */
  savePlantType: PlantType;
  /** send orderList as email or fax  to the supplier */
  sendOrderList: Scalars['Boolean']['output'];
  /**
   * shipLabel creates an shipping label for a specific carrier on shipcloud platform and prints it!
   * @returns paketjournalID for canceling the label if accidental created
   */
  shipLabel: ShipLabelResponse;
  /** shipCancel try to cancel a shipment from shipcloud @ the carrier (Only possible until a specific time, carrier specific */
  shipLabelCancel: Scalars['Boolean']['output'];
  sortOrder: SortOrderRes;
  /** specialChanges sets special options for the specified label */
  specialChanges: Scalars['Boolean']['output'];
  /** claim supplier collection */
  supplierCollectionClaim: Scalars['Boolean']['output'];
  /** release supplier collection */
  supplierCollectionRelease: Scalars['Boolean']['output'];
  /** updates a supplier collection */
  supplierCollectionUpdate: Scalars['Boolean']['output'];
  /** insert or update supplier container */
  supplierContainersUpsert: Scalars['Boolean']['output'];
  /** Create a new supplier. */
  supplierCreate: Supplier;
  /**
   * supplierRoundInfoSave updates the note for a specific supplier in a specific round
   * @round = the round number the id will be determined by the backend
   * @supplierID = the supplier id (lieferanten(kurzwahl))
   * @note = max 255 characters
   */
  supplierRoundInfoSave: Scalars['Boolean']['output'];
  /**
   * supplierRoundItemDelete deletes an item from the round list
   * @roundListIDs = the id of the item in the round list can be fetched from the API
   */
  supplierRoundItemDelete: Scalars['Boolean']['output'];
  /**
   * supplierRoundItemMove moves an item from one supplier to another
   * if the quantity is 0 the item will be deleted
   * also it checks if the new supplier can deliver the item
   * after this call the frontend should be reloaded to get the new data
   * @roundListID = the id of the item in the round list can be fetched from the API
   * @round = the round number
   * @newSupplierID = the new supplier id
   * @quantity = how much should be moved to the new supplier
   */
  supplierRoundItemMove: Scalars['Boolean']['output'];
  /** Update a existing supplier. */
  supplierUpdate: Supplier;
  /** tagItem labels an item as received and ready to be further processed */
  tagItem: ScanResponse;
  /** timeLineInsert */
  timelineInsert: Timeline;
  /**
   * save a user
   * if id = 0
   *   create a new user
   * else
   *   update the user with the given id
   */
  userSave: User;
  userSettingSave: UserSettingSaveResponse;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationAddCustomerDebitArgs = {
  in: CustomerDebitInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleDetailsSaveArgs = {
  in: ArticleDetailsInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleNewArgs = {
  input: ArticleNewInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticlePositionArgs = {
  input: ArticlePositionInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSplitArgs = {
  in: ArticleSplitInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierDeleteArgs = {
  in: ArticleSupplierInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierSaveArgs = {
  in: ArticleSupplierInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleSupplierSwapPriorityArgs = {
  id1: Scalars['Int']['input'];
  id2: Scalars['Int']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationArticleUpdateStorageplaceArgs = {
  input: ArticleStoragePlaceInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxCreateArgs = {
  in: CartonInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxDeleteArgs = {
  id: Scalars['Int']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationBoxUpdateArgs = {
  in: CartonInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponCreateArgs = {
  in?: InputMaybe<CouponCreateInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponDeleteArgs = {
  in?: InputMaybe<CouponDeleteInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCouponUpdateArgs = {
  in?: InputMaybe<CouponUpdateInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCreateRsShipmentArgs = {
  in: RsShipmentInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerAddressSaveArgs = {
  in: AddressInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerDetailsSaveArgs = {
  in: CustomerDetailInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerOrderCreateArgs = {
  in: CustomerOrderInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerRefundCreateArgs = {
  in: CustomerRefundCreateInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationCustomerRefundSettleArgs = {
  in: CustomerRefundSettleInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationDeleteFilterTemplateArgs = {
  name: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationDeletePlantTypeArgs = {
  id: Scalars['Int']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationEditAskDanteUserTagsArgs = {
  tags: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationFreshdeskTicketCreateArgs = {
  in: FreshdeskTicketInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderCancelArgs = {
  in: OrderCancelInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderChangeArgs = {
  in: OrderChangeInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemDistributeArgs = {
  in: OrderItemDistributeInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemResetArgs = {
  in: OrderItemResetInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderItemsCancelArgs = {
  in: OrderItemsCancelInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationOrderSplitArgs = {
  in: OrderSplitInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationPackErrorResolveArgs = {
  id: Scalars['Int']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRabenShipmentCreateArgs = {
  in: RabenShipmentInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRmlShipmentCreateArgs = {
  in: RmlShipmentInput;
  printer: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundCreateArgs = {
  in: RoundCreateInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemEditDescriptionArgs = {
  in: RoundItemEditDescriptionInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsAddArgs = {
  in: Array<RoundItemAddInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsEditArgs = {
  in: RoundItemEditInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationRoundItemsSplitArgs = {
  in: RoundItemsSplitInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSaveArticlePlantTypesArgs = {
  in: SaveArticlePlantTypeInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSaveFilterTemplateArgs = {
  name: Scalars['String']['input'];
  template: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSavePlantTypeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSendOrderListArgs = {
  in: SendOrderListInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationShipLabelArgs = {
  in: ShiplabelInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationShipLabelCancelArgs = {
  id: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSortOrderArgs = {
  in: SortOrderInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSpecialChangesArgs = {
  in: SpecialChangesInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierCollectionClaimArgs = {
  in?: InputMaybe<SupplierCollectionClaimInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierCollectionReleaseArgs = {
  in?: InputMaybe<SupplierCollectionReleaseInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierCollectionUpdateArgs = {
  in?: InputMaybe<SupplierCollectionUpdateInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierContainersUpsertArgs = {
  in?: InputMaybe<SupplierContainersUpsertInput>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierCreateArgs = {
  in: SupplierInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundInfoSaveArgs = {
  in: SupplierRoundInfoSaveInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundItemDeleteArgs = {
  roundListIDs: Array<Scalars['Int']['input']>;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierRoundItemMoveArgs = {
  newSupplierID: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  round: Scalars['Int']['input'];
  roundListID: Scalars['Int']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationSupplierUpdateArgs = {
  in: SupplierInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationTagItemArgs = {
  barcode: Scalars['String']['input'];
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationTimelineInsertArgs = {
  in: TimelineInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationUserSaveArgs = {
  in: UserUpdateInput;
};


/**
 * This is a description of the Mutation type.
 * It's used for modifying data on the server.
 */
export type MutationUserSettingSaveArgs = {
  in: UserSettingSaveInput;
};

export type NewOrdersByStatusRes = {
  __typename?: 'NewOrdersByStatusRes';
  rows: Array<PlantOrderSum>;
};

export type NoteInput = {
  nr: Scalars['Int']['input'];
  txt: Scalars['String']['input'];
};

export type Order = {
  __typename?: 'Order';
  deliveryAddress?: Maybe<Address>;
  /** total item count */
  itemCount: Scalars['Int']['output'];
  items: Array<Article>;
  orderID: Scalars['Int']['output'];
  packageCount: Scalars['Int']['output'];
  /** total plant count */
  plantCount: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  targetDate: Scalars['String']['output'];
};

export type OrderBillPdfResponse = {
  __typename?: 'OrderBillPDFResponse';
  msg: Scalars['String']['output'];
  pdfBase64: Scalars['String']['output'];
};

export enum OrderCancelAction {
  CreditBackToCustomerDeposit = 'CREDIT_BACK_TO_CUSTOMER_DEPOSIT',
  TransferToBankAccount = 'TRANSFER_TO_BANK_ACCOUNT'
}

export type OrderCancelInput = {
  action: OrderCancelAction;
  orderID: Scalars['String']['input'];
};

export type OrderChangeInput = {
  Frachtpausch: Scalars['Float']['input'];
  articles: Array<OrderItemInput>;
  custnote: Scalars['String']['input'];
  deliveryAdrId: Scalars['Int']['input'];
  notiz: Scalars['String']['input'];
  orderID: Scalars['String']['input'];
  rabatt: Scalars['Float']['input'];
  secretnote: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  zielDatum: Scalars['String']['input'];
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  customerEmail: Scalars['String']['output'];
  customerID: Scalars['Int']['output'];
  /**
   * telephone number or mobile number
   * mobile is preferred if available
   */
  customerPhone: Scalars['String']['output'];
  invoiceSum: Scalars['Float']['output'];
  note: Scalars['String']['output'];
  orderID: Scalars['Int']['output'];
  paymentMethod: Scalars['String']['output'];
  secretNote: Scalars['String']['output'];
  statusID: Scalars['Int']['output'];
  targetShipDate: Scalars['String']['output'];
};

export type OrderDetailsMore = {
  __typename?: 'OrderDetailsMore';
  bezahlt: Scalars['Float']['output'];
  couponKey: Scalars['String']['output'];
  couponValue: Scalars['Float']['output'];
  custnote: Scalars['String']['output'];
  datum: Scalars['String']['output'];
  deliveryAdr: Address;
  frachtpausch: Scalars['Float']['output'];
  kundnum: Scalars['Int']['output'];
  notiz: Scalars['String']['output'];
  orderID: Scalars['Int']['output'];
  orderItems: Array<OrderItem>;
  packages: Array<PackageStat>;
  rabatt: Scalars['Float']['output'];
  rechnum: Scalars['Int']['output'];
  rechsum: Scalars['Float']['output'];
  roundName: Scalars['String']['output'];
  rue: Scalars['Float']['output'];
  rueText: Scalars['String']['output'];
  secretnote: Scalars['String']['output'];
  sh: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  urorder: Scalars['Int']['output'];
  zahlart: Scalars['String']['output'];
  zieldat: Scalars['String']['output'];
};

export type OrderIsShipableResponse = {
  __typename?: 'OrderIsShipableResponse';
  IsShipable: Scalars['Boolean']['output'];
  msg: Scalars['String']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  anzahl: Scalars['Int']['output'];
  artnum: Scalars['Int']['output'];
  bestellt: Scalars['Boolean']['output'];
  bis: Scalars['Int']['output'];
  botname: Scalars['String']['output'];
  etikettiert: Scalars['Boolean']['output'];
  ewID: Scalars['Int']['output'];
  lager: Scalars['String']['output'];
  soldStatus: Scalars['String']['output'];
  source: Scalars['String']['output'];
  splitstatus: Scalars['Boolean']['output'];
  stahoeh: Scalars['Int']['output'];
  stubis: Scalars['Int']['output'];
  stueckpreis: Scalars['Float']['output'];
  stuvon: Scalars['Int']['output'];
  verteilt: Scalars['Boolean']['output'];
  von: Scalars['Int']['output'];
};

export type OrderItemCancelInput = {
  articleId: Scalars['String']['input'];
  count: Scalars['Int']['input'];
};

export type OrderItemDistributeInput = {
  barcode: Scalars['String']['input'];
  hasSuffixStep1: Scalars['Boolean']['input'];
};

export type OrderItemInput = {
  anzahl: Scalars['Int']['input'];
  bdb: Scalars['Int']['input'];
  ewId: Scalars['Int']['input'];
  stueckpreis: Scalars['Float']['input'];
};

export type OrderItemResetInput = {
  barcode: Scalars['String']['input'];
  hasSuffix: Scalars['Boolean']['input'];
};

export type OrderItemsCancelInput = {
  action: OrderCancelAction;
  orderID: Scalars['String']['input'];
  orderItems: Array<OrderItemCancelInput>;
};

export type OrderListInput = {
  date: Scalars['String']['input'];
  supplierID: Scalars['Int']['input'];
};

export type OrderListPreviewPdfResponse = {
  __typename?: 'OrderListPreviewPDFResponse';
  msg: Scalars['String']['output'];
  pdfBase64: Scalars['String']['output'];
};

export type OrderSplitInput = {
  items: Array<SplitItemInput>;
  mergeStatuses: Array<Scalars['Int']['input']>;
  targetStatus: Scalars['Int']['input'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  etiStop: Scalars['Boolean']['output'];
  packStop: Scalars['Boolean']['output'];
  remark: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  verteilenStop: Scalars['Boolean']['output'];
};

export type PackError = {
  __typename?: 'PackError';
  addressID: Scalars['Int']['output'];
  created: Scalars['String']['output'];
  customerID: Scalars['Int']['output'];
  detail: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  orderID: Scalars['Int']['output'];
  packer: Scalars['String']['output'];
};

export type PackageStat = {
  __typename?: 'PackageStat';
  carrier: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  labelURL: Scalars['String']['output'];
  orderID: Scalars['Int']['output'];
  packageNumber: Scalars['String']['output'];
  ps: Scalars['String']['output'];
  trackingURL: Scalars['String']['output'];
};

/**
 * use one of these:
 * height is kinda trivial one pallet == one place in the container
 * 1 - 120x80x100  - 150kg		  - euro pallet 			  ep
 * 2 - 60x80x100   -	75kg		  - half pallet 			  ds
 * 3 - 40x60x100   -	50kg		  - quarter pallet		  cp
 */
export type PalletInput = {
  Depth: Scalars['Float']['input'];
  Height: Scalars['Float']['input'];
  KG: Scalars['Float']['input'];
  TypeCode: Scalars['String']['input'];
  Width: Scalars['Float']['input'];
};

export type Performance = {
  __typename?: 'Performance';
  itemsDispatched: Scalars['Int']['output'];
  itemsDistributed: Scalars['Int']['output'];
  itemsTagged: Scalars['Int']['output'];
  worker: Scalars['String']['output'];
};

export type PlantOrderSum = {
  __typename?: 'PlantOrderSum';
  bdb: Scalars['String']['output'];
  botname: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  singles: Scalars['Int']['output'];
  soldStatus: Scalars['String']['output'];
  supplier: Scalars['String']['output'];
};

export type PlantType = {
  __typename?: 'PlantType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PrintDeliveryNoteInput = {
  orderID: Scalars['Int']['input'];
  printerIpPort: Scalars['String']['input'];
};

export type Printer = {
  __typename?: 'Printer';
  printerID: Scalars['Int']['output'];
  printerIP: Scalars['String']['output'];
  printerName: Scalars['String']['output'];
  printerPort: Scalars['String']['output'];
};

/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type Query = {
  __typename?: 'Query';
  /** returns the 4 active rounds back */
  activeRounds: Array<Scalars['String']['output']>;
  /**
   * articleChangelog get all changes for a specific article
   * what was done in the past on this article
   */
  articleChangelog: Array<ArticleChangelogItem>;
  /** articleCheckEanExists checks if an ean exists in the db */
  articleCheckEanExists: Scalars['Boolean']['output'];
  /**
   * articleDetails gives you back information about a specific article
   * BY BDB
   */
  articleDetails: ArticleDetail;
  /** query all data from the inventory report */
  articleInventoryReports: Array<ArticleInventoryReport>;
  /** articlePriceHistory get all price changes for a specific article */
  articlePriceHistory: Array<ArticlePriceHistory>;
  /**
   * search article database for a specific article by a search term which will include search by:
   * BDB
   * Botanic Name
   */
  articleSearch: Array<ArticleItem>;
  /** articleStatus retrieves the current state of an order */
  articleStatus: Order;
  /** get all supplier for one article ordered by priority */
  articleSupplier: Array<ArticleSupplier>;
  /** articleSupplierInfo get each specific orderItem (not summed up) for a supplierId and round */
  articleSupplierInfo: Array<SupplierOrderItem>;
  /** articleSupplierRefs get all other suppliers for articles that are also ordered from the given supplierId and round */
  articleSupplierRefs: Array<ArticleSupplierCrossRef>;
  /** load a variant array for a specific article by pflnr */
  articleVariants: Array<ArticleItem>;
  /** askDanteUsers get all askDante users with corresponding tags */
  askDanteUsers: Array<AskDanteUser>;
  /** Get a list of all cartons, which were created */
  boxList: Array<Carton>;
  /** Collect a list of box and their statistics with the given parameters. The start and end are required and shouldn't be empty. The carrier and orderId are optional. */
  boxStatistics: Array<CartonStatistic>;
  /** Get a list of all carriers which are used in the package journal */
  carriers: Array<Scalars['String']['output']>;
  /** returns a list of all categories */
  categories: Array<Category>;
  /** get the specified coupon */
  couponById?: Maybe<Coupon>;
  /** returns true if at least one coupon with the specified key exists */
  couponKeyInUse?: Maybe<Scalars['Boolean']['output']>;
  /** gets coupons active on the specified date */
  couponsByActiveDate: Array<Coupon>;
  /** get the specified coupon by key */
  couponsByKey: Array<Coupon>;
  customerAddress: Array<Address>;
  customerById: CustomerDetail;
  customerDebit: Array<CustomerDebit>;
  customerOrders: Array<CustomerOrder>;
  /** customerRefunds get all customer refunds */
  customerRefunds: CustomerRefundResponse;
  customerRelatedOrders: Array<CustomerOrder>;
  /**
   * customerSearch search for a user by a search term
   * database will be searched for:
   *   id
   *   first_name
   *   last_name
   *   email
   *   username
   */
  customerSearch: Array<CustomerSearchRes>;
  /** download a pdf as base64 string from a specific url and return it as a string base64 */
  downloadPdf: Scalars['String']['output'];
  /** get all employee statistics within the specified time range */
  employeeStats: EmployeeStatsOutput;
  /** export pdf bill for a specific order */
  exportBill: Scalars['String']['output'];
  /** load filter templates */
  filterTemplates: Array<FilterTemplate>;
  /** getEtikettenRound get all etiketten data for a specific round */
  getEtikettenRound: Array<EtikettenData>;
  /**
   * getNextRoundNumber get the next round number
   * (according to the database check which could be the next round number for displaying purpose in the frontend so the user
   * can create a new round with the next round number)
   */
  getNextRoundNumber: Scalars['Int']['output'];
  /** get plant type by bdb */
  getPlantType: Array<PlantType>;
  /** get a list of splitable and mergeable status that can be set for the split and merge action */
  getSplitMergeStatuses: Array<SplitableStatus>;
  /** returns a list of suppliercontainer */
  getSupplierContainerList: Array<SupplierContainer>;
  isOrderShipable: OrderIsShipableResponse;
  /** libraList get a list of all libras on the db */
  libraList: Array<Libra>;
  /**
   * login with username and password and get a JWT token which can be used for authentication in the authorization header.
   * for example: Authorization: "Bearer <token>"
   * the token is valid for 24 hours. For security reasons, the token is not stored in the database and is not refreshable.
   * this endpoint can't be used here, because it would introduce too much overhead on the server with parsing the GQL.
   * USE login REST ENDPOINT INSTEAD it has the same type and input in a restful way.
   */
  login: AuthResponse;
  /** Get the logistic statistics of the current day back. */
  logisticStat: LogisticStat;
  newOrdersByStatus: NewOrdersByStatusRes;
  /** get order details by order id */
  orderByID: OrderDetailsMore;
  /**
   * orderByStatus get all customer created orders with article details as list
   * by a specific status
   */
  orderByStatus: Array<RoundCreateOrder>;
  /** get all order lists with supplier which can be printed */
  orderListGetPrintItems: Array<RoundOrderPrintItem>;
  /** preview orderList pdf for a specific round and supplier */
  orderListPreviewPDF: OrderListPreviewPdfResponse;
  /**
   * print orderList pdf for a specific round and supplier array (multiple suppliers)
   * @returns message if the pdf was printed successfully (or not with a good message for the ENDUSER)
   * the STRING return value contains expected errors from the server
   * the supplierIDs get iterated and each request ist printed synchronously
   * IF one fails the FIRST error is returned and displayed (in the graphql errors)
   */
  orderListPrintPDFs: Scalars['String']['output'];
  /** orderStatus list all order status */
  orderStatus: Array<OrderStatus>;
  /** get timeline entries for a specific order */
  orderTimeline: Array<Timeline>;
  /** get a list of orders by status and BDB */
  ordersByStatusAndBDB: Array<SortOrderInfo>;
  /** Get a list of all pack errors. */
  packErrors: Array<PackError>;
  /**
   * get a list of all sent pacakges filtered by from to dates in the format of:
   * 2012-2-2 = yyyy-MM-dd without the prefix 0
   */
  packageStats: Array<PackageStat>;
  packageStatsByOrderID: Array<PackageStat>;
  /**
   * get the performance of the workers with a date time span.
   * with the format: yyyy-MM-dd
   * the reason we use that format is that the database can work with that
   */
  performance: Array<Performance>;
  /** plantTypes get all plant types */
  plantTypes: Array<PlantType>;
  /**
   * printDailyQRcode from a zebraprinter ( scan barcode on the printer )
   * each day a token is generated and saved in the db
   * it will be valid until 23:59:59
   */
  printDailyQRCode: Scalars['Boolean']['output'];
  /** print a delivery note for an order (by order id) the server checks if the note was already printed once */
  printDeliveryNote: Scalars['Boolean']['output'];
  /** print etiketten for a specific round and supplierIds on printerName (cups) */
  printEtiketten: Scalars['String']['output'];
  /** print orderList kistenZettel for a specific round ( all orders from customers ) */
  printOrderListKistenZettel: Scalars['String']['output'];
  /** print rs logistic label again */
  printRsLabel: Scalars['Boolean']['output'];
  printShipcloudLabel: Scalars['Boolean']['output'];
  /** get a list of all printers */
  printers: Array<Printer>;
  /** docID is provided from raben */
  rabenLabelPrint: Scalars['Boolean']['output'];
  /** rmlLabelPrint */
  rmlLabelPrint: Scalars['Boolean']['output'];
  /** roundOrderItems get all order items for a specific round */
  roundOrderList: RoundOrderListResponse;
  /** roundOrderdata get all order data for a specific round */
  roundOrderdata: Array<RoundOrderdata>;
  /** gets the data required for the round order info screen */
  roundOrdersGet: RoundOrdersResponse;
  /** gets the data required for the round overview screen */
  roundOverviewGet: RoundOverviewResponse;
  /**
   * Collect the statistics for the given rounds.
   * For example if in the input rounds: [1,2,3] is given, the statistics for the first three rounds will be returned.
   * If none are given, the statistics for the current 4 rounds will be returned.
   */
  roundStats: Array<RoundStat>;
  /** Collect a list of supplier with statistic of the given round. */
  roundSuppliers: Array<RoundSupplier>;
  /** gets all round id's */
  roundsGet: RoundsGetResponse;
  /** returns the sales stats for the given date range */
  salesStats: Array<SalesStat>;
  /** get details for an order */
  shipmentDetails: ShipmentDetails;
  /** returns a list of all open collections */
  supplierCollections: Array<SupplierCollection>;
  /**
   * supplier ordered items get items that have been ordered from suppliers in
   * a specific round.
   * It will all be fetched and can be filtered in the frontend.
   */
  supplierOrderedItems: Array<SupplierOrderedItems>;
  /** supplierRoundInfos get all notes for a specific a specific round */
  supplierRoundInfos: Array<SupplierRoundInfo>;
  /** Get a list of all suppliers. */
  suppliers: Array<Supplier>;
  /** userAchievment get the current worker performance(achievment) for the current day */
  userAchievment: UserHistory;
  /** returns a list of collections associated with a user */
  userSupplierCollections: Array<SupplierCollection>;
  /** Get a list of all users. */
  users: Array<User>;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleChangelogArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleCheckEanExistsArgs = {
  ean: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleDetailsArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleInventoryReportsArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticlePriceHistoryArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSearchArgs = {
  getInactive: Scalars['Boolean']['input'];
  t: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleStatusArgs = {
  barcode: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierInfoArgs = {
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleSupplierRefsArgs = {
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryArticleVariantsArgs = {
  getInactive: Scalars['Boolean']['input'];
  pflnr: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryBoxStatisticsArgs = {
  carrier: Scalars['String']['input'];
  end: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  start: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCategoriesArgs = {
  filter?: InputMaybe<CategoryFilterInput>;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponByIdArgs = {
  in?: InputMaybe<CouponByIdInput>;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponKeyInUseArgs = {
  key: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponsByActiveDateArgs = {
  in: CouponsByActiveDateInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCouponsByKeyArgs = {
  in: CouponsByKeyInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerAddressArgs = {
  customerID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerByIdArgs = {
  customerID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerDebitArgs = {
  in: CustomerDebitRequest;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerOrdersArgs = {
  customerID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerRefundsArgs = {
  in: CustomerRefundInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerRelatedOrdersArgs = {
  oid: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryCustomerSearchArgs = {
  in: CustomerSearchInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryDownloadPdfArgs = {
  carrier: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryEmployeeStatsArgs = {
  in: EmployeeStatsInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryExportBillArgs = {
  orderID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryGetEtikettenRoundArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryGetPlantTypeArgs = {
  bdb: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryIsOrderShipableArgs = {
  oid: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryLoginArgs = {
  in: AuthRequest;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryNewOrdersByStatusArgs = {
  status: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderByIdArgs = {
  orderID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderByStatusArgs = {
  status: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListGetPrintItemsArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListPreviewPdfArgs = {
  date: Scalars['String']['input'];
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderListPrintPdFsArgs = {
  printerName: Scalars['String']['input'];
  round: Scalars['Int']['input'];
  supplier: Array<OrderListInput>;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrderTimelineArgs = {
  orderID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryOrdersByStatusAndBdbArgs = {
  bdb: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPackageStatsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPackageStatsByOrderIdArgs = {
  orderID: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPerformanceArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintDailyQrCodeArgs = {
  printerID: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintDeliveryNoteArgs = {
  in: PrintDeliveryNoteInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintEtikettenArgs = {
  isSteck: Scalars['Boolean']['input'];
  printerIpPort: Scalars['String']['input'];
  round: Scalars['Int']['input'];
  selectedEwIds: Array<Scalars['Int']['input']>;
  supplierIDs: Array<Scalars['Int']['input']>;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintOrderListKistenZettelArgs = {
  orderIds: Array<Scalars['Int']['input']>;
  printerIpPort: Scalars['String']['input'];
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintRsLabelArgs = {
  printerIpPort: Scalars['String']['input'];
  rsLabelURL: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryPrintShipcloudLabelArgs = {
  printerIpPort: Scalars['String']['input'];
  shipcloudLabelURL: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRabenLabelPrintArgs = {
  docID: Scalars['String']['input'];
  printerIpPort: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRmlLabelPrintArgs = {
  printerIpPort: Scalars['String']['input'];
  rmlOrderID: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrderListArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrderdataArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOrdersGetArgs = {
  in: RoundOrdersInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundOverviewGetArgs = {
  in: RoundOverviewInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundStatsArgs = {
  in: RoundStatInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryRoundSuppliersArgs = {
  in: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySalesStatsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryShipmentDetailsArgs = {
  orderID: Scalars['String']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySupplierCollectionsArgs = {
  filter: SupplierCollectionFilterInput;
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySupplierOrderedItemsArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QuerySupplierRoundInfosArgs = {
  round: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryUserAchievmentArgs = {
  userID: Scalars['Int']['input'];
};


/**
 * This is a description of the Query type.
 * It's used for fetching data from the server.
 */
export type QueryUserSupplierCollectionsArgs = {
  filter: UserSupplierCollectionFilterInput;
};

export type RabenShipmentInput = {
  CountryCode: Scalars['String']['input'];
  avd: Scalars['Boolean']['input'];
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  logisticService: Scalars['String']['input'];
  myd: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  orderID: Scalars['Int']['input'];
  pallet: PalletInput;
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  printerIpPort: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type RabenShipmentResponse = {
  __typename?: 'RabenShipmentResponse';
  DocID: Scalars['String']['output'];
};

export type RatingValue = {
  __typename?: 'RatingValue';
  count: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

export type RmlHinweis = {
  code: Scalars['String']['input'];
  info: Scalars['String']['input'];
};

export type RmlPalletInput = {
  heightMM: Scalars['Int']['input'];
  lengthMM: Scalars['Int']['input'];
  palletCode: Scalars['String']['input'];
  palletCount: Scalars['Int']['input'];
  weightGramm: Scalars['Int']['input'];
  widthMM: Scalars['Int']['input'];
};

export type RmlShipmentInput = {
  comment: Scalars['String']['input'];
  deliveryAdrId: Scalars['Int']['input'];
  hinweise: Array<RmlHinweis>;
  orderGoodDescription: Scalars['String']['input'];
  orderID: Scalars['String']['input'];
  pallets: Array<RmlPalletInput>;
  termcode: Scalars['String']['input'];
  termdata: Scalars['String']['input'];
};

export type Round = {
  __typename?: 'Round';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  round: Scalars['Int']['output'];
};

export type RoundCreateInput = {
  endDate: Scalars['String']['input'];
  round: Scalars['Int']['input'];
  roundName: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  status: Scalars['Int']['input'];
};

export type RoundCreateOrder = {
  __typename?: 'RoundCreateOrder';
  bdb: Scalars['String']['output'];
  botname: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  ewid: Scalars['String']['output'];
  orderID: Scalars['String']['output'];
  supplier?: Maybe<SupplierInfo>;
};

export type RoundCreateResponse = {
  __typename?: 'RoundCreateResponse';
  msg: Scalars['String']['output'];
};

export type RoundDataRow = {
  __typename?: 'RoundDataRow';
  crates: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  delivered: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ordered: Scalars['Int']['output'];
  round: Scalars['Int']['output'];
  sentMessages: Scalars['Int']['output'];
  singleSum: Scalars['Int']['output'];
  totalMessages: Scalars['Int']['output'];
  totalOrderCount: Scalars['Int']['output'];
};

export type RoundItemAddInput = {
  articleID: Scalars['Int']['input'];
  count: Scalars['Int']['input'];
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};

export type RoundItemEditDescriptionInput = {
  articleID: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};

export type RoundItemEditInput = {
  articleID: Scalars['Int']['input'];
  count: Scalars['Int']['input'];
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};

export type RoundItemsSplitInput = {
  mergeStatuses: Array<Scalars['Int']['input']>;
  roundListIds: Array<Scalars['Int']['input']>;
  statusCode: Scalars['Int']['input'];
};

export type RoundItemsSplitResponse = {
  __typename?: 'RoundItemsSplitResponse';
  message: Scalars['String']['output'];
};

export type RoundOrderArticle = {
  __typename?: 'RoundOrderArticle';
  articleId: Scalars['String']['output'];
  botName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  quantityForCustomers: Scalars['Int']['output'];
  quantityForStorage: Scalars['Int']['output'];
  quantityTotal: Scalars['Int']['output'];
  sizeMax: Scalars['Int']['output'];
  sizeMin: Scalars['Int']['output'];
  supplierContainerSize: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type RoundOrderItem = {
  __typename?: 'RoundOrderItem';
  email: Scalars['String']['output'];
  emailSentAt: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  faxSentAt: Scalars['String']['output'];
  flavour: Scalars['Int']['output'];
  g24: Scalars['Boolean']['output'];
  inStorage: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  round: Scalars['Int']['output'];
  sendEmail: Scalars['Boolean']['output'];
  sendFax: Scalars['Boolean']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type RoundOrderListResponse = {
  __typename?: 'RoundOrderListResponse';
  items: Array<RoundOrderItem>;
  roundExists: Scalars['Boolean']['output'];
};

export type RoundOrderPrintItem = {
  __typename?: 'RoundOrderPrintItem';
  emailSentAt: Scalars['String']['output'];
  faxSentAt: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type RoundOrderdata = {
  __typename?: 'RoundOrderdata';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  kundnum: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  notiz: Scalars['String']['output'];
  orderid: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  street: Scalars['String']['output'];
  totalArticleCount: Scalars['Int']['output'];
  zahlart: Scalars['String']['output'];
  zieldat: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type RoundOrdersInput = {
  round: Scalars['Int']['input'];
};

export type RoundOrdersResponse = {
  __typename?: 'RoundOrdersResponse';
  orderArticles: Array<RoundOrderArticle>;
  totalOrdersCount: Scalars['Int']['output'];
};

export type RoundOverviewInput = {
  currentPage: Scalars['Int']['input'];
  rowsPerPage: Scalars['Int']['input'];
};

export type RoundOverviewResponse = {
  __typename?: 'RoundOverviewResponse';
  roundDataRows: Array<RoundDataRow>;
  totalRoundCount: Scalars['Int']['output'];
};

/** represents a round of the system. */
export type RoundStat = {
  __typename?: 'RoundStat';
  articleCount: Scalars['Int']['output'];
  distributedArticleCount: Scalars['Int']['output'];
  idExists: Scalars['Boolean']['output'];
  openOrderCount: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  roundID: Scalars['Int']['output'];
  roundName: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  taggedArticleCount: Scalars['Int']['output'];
};

export type RoundStatInput = {
  rounds: Array<Scalars['String']['input']>;
};

/** represents a supplier of the system. */
export type RoundSupplier = {
  __typename?: 'RoundSupplier';
  expected: Scalars['Int']['output'];
  rest: Scalars['Int']['output'];
  supplier: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  tagged: Scalars['Int']['output'];
  taggedbybot: Scalars['Int']['output'];
};

export type RoundsGetResponse = {
  __typename?: 'RoundsGetResponse';
  rounds: Array<Round>;
};

export type RsShipmentInput = {
  GP: Scalars['Int']['input'];
  HP: Scalars['Int']['input'];
  SP: Scalars['Int']['input'];
  VP: Scalars['Int']['input'];
  carrierID: Scalars['String']['input'];
  mainNote: Scalars['String']['input'];
  notes: Array<NoteInput>;
  orderID: Scalars['String']['input'];
  /** if true the package will be placed on the property of the customer. */
  permitToPlacePackage: Scalars['Boolean']['input'];
  printerName: Scalars['String']['input'];
};

export type RsShipmentResponse = {
  __typename?: 'RsShipmentResponse';
  /**
   * a list of things that went wrong during the shipment process
   * it's purpose is to give the user information about what went wrong
   */
  Info: Array<Scalars['String']['output']>;
  PDFURL: Scalars['String']['output'];
};

export type SalesStat = {
  __typename?: 'SalesStat';
  artnum: Scalars['Int']['output'];
  /** a entry for a sales stats for a specific day */
  botname: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  gername: Scalars['String']['output'];
  pflNR: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  to: Scalars['Int']['output'];
};

export type SaveArticlePlantTypeInput = {
  bdb: Scalars['Int']['input'];
  plantTypeIds: Array<Scalars['Int']['input']>;
};

export type ScanResponse = {
  __typename?: 'ScanResponse';
  orderDetails?: Maybe<Order>;
  scannedArticle?: Maybe<Article>;
  status: Status;
};

export type SendOrderListInput = {
  round: Scalars['Int']['input'];
  sendEmail: Scalars['Boolean']['input'];
  sendFax: Scalars['Boolean']['input'];
  supplier: Array<OrderListInput>;
};

export type ShipLabelResponse = {
  __typename?: 'ShipLabelResponse';
  journalId: Scalars['Int']['output'];
  msg: Array<Scalars['String']['output']>;
};

export type ShiplabelInput = {
  LibraIpPort: Scalars['String']['input'];
  PrinterIpPort: Scalars['String']['input'];
  carrier: Carrier;
  cartonBarcode: Scalars['String']['input'];
  orderID: Scalars['String']['input'];
};

export type ShipmentDetails = {
  __typename?: 'ShipmentDetails';
  addressDetails: Address;
  custEmail: Scalars['String']['output'];
  custMobile: Scalars['String']['output'];
  custPhone: Scalars['String']['output'];
  msg: Array<Scalars['String']['output']>;
  orderDetails: OrderDetails;
  payOnDelivery: Scalars['Boolean']['output'];
  pdfURL: Scalars['String']['output'];
  /** if set to true the package will be placed at the garage or something */
  permitToPlacePackage: Scalars['Boolean']['output'];
};

export type SortOrderInfo = {
  __typename?: 'SortOrderInfo';
  country: Scalars['String']['output'];
  custId: Scalars['Int']['output'];
  ewID: Scalars['Int']['output'];
  isSingle: Scalars['Boolean']['output'];
  notiz: Scalars['String']['output'];
  orderID: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  zielDat: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type SortOrderInput = {
  createNewRound: Scalars['Boolean']['input'];
  fromDate: Scalars['String']['input'];
  newStatus: Scalars['String']['input'];
  oldStatus: Scalars['String']['input'];
  onlyEmptyNotiz: Scalars['Boolean']['input'];
  onlySingles: Scalars['Boolean']['input'];
  paymentMethod: Scalars['String']['input'];
  plantTypeIDs: Array<Scalars['Int']['input']>;
  preview: Scalars['Boolean']['input'];
  roundDetails?: InputMaybe<RoundCreateInput>;
  splitSoldOut: Scalars['Boolean']['input'];
  supplierIDs: Array<Scalars['Int']['input']>;
  toDate: Scalars['String']['input'];
};

export type SortOrderRes = {
  __typename?: 'SortOrderRes';
  message: Scalars['String']['output'];
  preview: Array<PlantOrderSum>;
};

export type SpecialChangesInput = {
  barcode: Scalars['String']['input'];
  isPalletArticle: Scalars['Boolean']['input'];
  pmLocation?: InputMaybe<Scalars['String']['input']>;
};

export type SplitItemInput = {
  articleId: Scalars['Int']['input'];
  custId: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
};

export type SplitableStatus = {
  __typename?: 'SplitableStatus';
  etiStop: Scalars['Boolean']['output'];
  mergeable: Scalars['Boolean']['output'];
  packStop: Scalars['Boolean']['output'];
  remark: Scalars['String']['output'];
  splitable: Scalars['Boolean']['output'];
  status: Scalars['Int']['output'];
  verteilenStop: Scalars['Boolean']['output'];
};

export enum Status {
  AlreadyDistributed = 'ALREADY_DISTRIBUTED',
  AlreadyTagged = 'ALREADY_TAGGED',
  BarcodeInvalid = 'BARCODE_INVALID',
  OrderComplete = 'ORDER_COMPLETE',
  PlantSplitted = 'PLANT_SPLITTED',
  Stop = 'STOP',
  Success = 'SUCCESS'
}

export type Supplier = {
  __typename?: 'Supplier';
  G24: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  city: Scalars['String']['output'];
  doegeGBF: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  flavour: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inStorage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  sendEmail: Scalars['Boolean']['output'];
  sendFax: Scalars['Boolean']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type SupplierAddress = {
  __typename?: 'SupplierAddress';
  city: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type SupplierCollection = {
  __typename?: 'SupplierCollection';
  address: SupplierAddress;
  collectedAt?: Maybe<Scalars['String']['output']>;
  container: Array<Maybe<SupplierContainer>>;
  count: Scalars['Int']['output'];
  driverName?: Maybe<Scalars['String']['output']>;
  isCollected?: Maybe<Scalars['Boolean']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  pickupDate: Scalars['String']['output'];
  pickupTime: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  requiresCollection?: Maybe<Scalars['Boolean']['output']>;
  round: Scalars['Int']['output'];
  supplierId: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type SupplierCollectionClaimInput = {
  runde: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
  userID: Scalars['Int']['input'];
};

export type SupplierCollectionFilterInput = {
  activeOnly: Scalars['Boolean']['input'];
};

export type SupplierCollectionReleaseInput = {
  runde: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};

export type SupplierCollectionUpdateInput = {
  completed: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  runde: Scalars['Int']['input'];
  supplierId: Scalars['Int']['input'];
};

export type SupplierContainer = {
  __typename?: 'SupplierContainer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  owed: Scalars['Int']['output'];
  supplierId: Scalars['Int']['output'];
};

export type SupplierContainerInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  owed: Scalars['Int']['input'];
  supplierId: Scalars['Int']['input'];
};

export type SupplierContainersUpsertInput = {
  container: Array<InputMaybe<SupplierContainerInput>>;
  supplierId: Scalars['Int']['input'];
};

export type SupplierInfo = {
  __typename?: 'SupplierInfo';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SupplierInput = {
  G24: Scalars['Boolean']['input'];
  GBF: Scalars['Boolean']['input'];
  active: Scalars['Boolean']['input'];
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fax: Scalars['String']['input'];
  flavour: Scalars['Int']['input'];
  /** set the id to 0 if you want to create a new supplier. */
  id: Scalars['Int']['input'];
  inStorage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  sendEmail: Scalars['Boolean']['input'];
  sendFax: Scalars['Boolean']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type SupplierOrder = {
  __typename?: 'SupplierOrder';
  articleID: Scalars['String']['output'];
  botanicName: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  gerName: Scalars['String']['output'];
  orderID: Scalars['String']['output'];
  orderItemID: Scalars['String']['output'];
  round: Scalars['Int']['output'];
  sh: Scalars['Int']['output'];
  source: Scalars['Int']['output'];
  source1: Scalars['String']['output'];
  source1_p: Scalars['Float']['output'];
  source2: Scalars['String']['output'];
  source2_p: Scalars['Float']['output'];
  source3: Scalars['String']['output'];
  source3_p: Scalars['Float']['output'];
  source4: Scalars['String']['output'];
  source4_p: Scalars['Float']['output'];
  source5: Scalars['String']['output'];
  source5_p: Scalars['Float']['output'];
};

export type SupplierOrderItem = {
  __typename?: 'SupplierOrderItem';
  bdb: Scalars['String']['output'];
  botname: Scalars['String']['output'];
  ewID: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  orderID: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  size: Scalars['String']['output'];
  sorte: Scalars['String']['output'];
  supplierID: Scalars['Int']['output'];
  supplierName: Scalars['String']['output'];
};

export type SupplierOrderedItems = {
  __typename?: 'SupplierOrderedItems';
  BOTNAME: Scalars['String']['output'];
  Bis: Scalars['Int']['output'];
  CreatedAt: Scalars['String']['output'];
  Ewid: Scalars['Int']['output'];
  Flavour: Scalars['Int']['output'];
  G24: Scalars['Int']['output'];
  GERNAME: Scalars['String']['output'];
  Is24hDelivery: Scalars['Boolean']['output'];
  IsTagged: Scalars['Boolean']['output'];
  Lieferant: Scalars['String']['output'];
  MainbaseBdb: Scalars['Int']['output'];
  Orderid: Scalars['Int']['output'];
  Quantity: Scalars['Int']['output'];
  RoundListID: Scalars['Int']['output'];
  Sorte: Scalars['String']['output'];
  SupplierID: Scalars['Int']['output'];
  Von: Scalars['Int']['output'];
};

export type SupplierRoundInfo = {
  __typename?: 'SupplierRoundInfo';
  collectionDate?: Maybe<Scalars['String']['output']>;
  collectionTime?: Maybe<Scalars['String']['output']>;
  note: Scalars['String']['output'];
  requiresCollection: Scalars['Boolean']['output'];
  supplierId: Scalars['Int']['output'];
};

export type SupplierRoundInfoSaveInput = {
  collectionDate?: InputMaybe<Scalars['String']['input']>;
  collectionTime?: InputMaybe<Scalars['String']['input']>;
  note: Scalars['String']['input'];
  requiresCollection: Scalars['Boolean']['input'];
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
};

export type Timeline = {
  __typename?: 'Timeline';
  action: Scalars['String']['output'];
  computername: Scalars['String']['output'];
  flavour?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  orderID: Scalars['Int']['output'];
  username: Scalars['String']['output'];
  zeitpunkt: Scalars['String']['output'];
};

export type TimelineInput = {
  action: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
};

/** represents a user of the system. */
export type User = {
  __typename?: 'User';
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  /**
   * Priviliges will be saved as a bit mask. The following privileges are available:
   * IF admin is set, all other privileges are ignored.
   * So if you want to compare the privileges, you have to check if the admin flag is set.
   * for example privileges & 1 = 1 -> admin
   * this is and int32 value, so you can combine multiple privileges by adding them up.
   * for example: privileges = 1 + 2  = 3 == 11 in binary == ADMIN | TAG_ITEMS == 1 | 2
   * ADMIN can create new users and assign privileges to them.
   * TAG can tag items as received in the warehouse.
   * DISTRIBUTE can distribute items to the corresponding orders.
   * DISPATCH can dispatch items to the customer in the warehouse.
   * 1: ADMIN
   * 2: TAG
   * 4: DISTRIBUTE
   * 8: DISPATCH
   * 16: SPLIT
   * 32: DEACTIVATE ACCOUNT
   */
  privileges: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

/** userAchievements, userHistory, and articleHistroy for statusBar */
export type UserHistory = {
  __typename?: 'UserHistory';
  articleHistory: Array<ArticleHistory>;
  itemsDistributed: Scalars['Int']['output'];
  itemsLabled: Scalars['Int']['output'];
  packagesDispatched: Scalars['Int']['output'];
};

export type UserSettingSaveInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UserSettingSaveResponse = {
  __typename?: 'UserSettingSaveResponse';
  message?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
};

export type UserSupplierCollectionFilterInput = {
  activeOnly: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
};

export type UserUpdateInput = {
  id: Scalars['Int']['input'];
  /**
   * if the password is empty, the password will not be changed.
   * since the password is hashed, it's not possible to get the old password back.
   * or lets say it's not easy to get the old password back.
   */
  newPassword: Scalars['String']['input'];
  privileges: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type ArticleCheckEanExistsQueryVariables = Exact<{
  ean: Scalars['String']['input'];
}>;


export type ArticleCheckEanExistsQuery = { __typename?: 'Query', articleCheckEanExists: boolean };

export type ArticleNewMutationVariables = Exact<{
  input: ArticleNewInput;
}>;


export type ArticleNewMutation = { __typename?: 'Mutation', articleNew: boolean };

export type ArticleNewDetailsMutationVariables = Exact<{
  input: OrderItemDistributeInput;
}>;


export type ArticleNewDetailsMutation = { __typename?: 'Mutation', orderItemDistribute: { __typename?: 'ScanResponse', scannedArticle?: { __typename?: 'Article', artNum: number, name: string, count: number, currentPrice: number, size: string } | null } };

export type ArticlePositionMutationVariables = Exact<{
  input: ArticlePositionInput;
}>;


export type ArticlePositionMutation = { __typename?: 'Mutation', articlePosition: number };

export type ArticleSplitMutationVariables = Exact<{
  in: ArticleSplitInput;
}>;


export type ArticleSplitMutation = { __typename?: 'Mutation', articleSplit: { __typename?: 'ArticleSplitResponse', statusCode: number, message?: string | null, orderDetails?: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, packageCount: number, targetDate: string, deliveryAddress?: { __typename?: 'Address', id: number, company: string, firstName: string, lastName: string, anrede: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } | null, newOrderDetails?: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, packageCount: number, targetDate: string, deliveryAddress?: { __typename?: 'Address', id: number, firstName: string, lastName: string, anrede: string, company: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } | null } };

export type ArticleStatusQueryVariables = Exact<{
  barcode: Scalars['String']['input'];
}>;


export type ArticleStatusQuery = { __typename?: 'Query', articleStatus: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, targetDate: string, packageCount: number, deliveryAddress?: { __typename?: 'Address', id: number, company: string, anrede: string, firstName: string, lastName: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } };

export type ArticleSupplierInfoQueryVariables = Exact<{
  round: Scalars['Int']['input'];
  supplierID: Scalars['Int']['input'];
}>;


export type ArticleSupplierInfoQuery = { __typename?: 'Query', articleSupplierInfo: Array<{ __typename?: 'SupplierOrderItem', id: number, orderID: number, ewID: number, supplierID: number, supplierName: string, bdb: string, quantity: number, botname: string, sorte: string, size: string }> };

export type ArticleUpdateStoragePlaceMutationVariables = Exact<{
  input: ArticleStoragePlaceInput;
}>;


export type ArticleUpdateStoragePlaceMutation = { __typename?: 'Mutation', articleUpdateStorageplace: boolean };

export type GetSupplierContainerListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplierContainerListQuery = { __typename?: 'Query', getSupplierContainerList: Array<{ __typename?: 'SupplierContainer', id: number, supplierId: number, name: string, owed: number }> };

export type IsOrderShipableQueryVariables = Exact<{
  oid: Scalars['Int']['input'];
}>;


export type IsOrderShipableQuery = { __typename?: 'Query', isOrderShipable: { __typename?: 'OrderIsShipableResponse', msg: string, IsShipable: boolean } };

export type LibraListQueryVariables = Exact<{ [key: string]: never; }>;


export type LibraListQuery = { __typename?: 'Query', libraList: Array<{ __typename?: 'Libra', id: number, ip: string, port: string }> };

export type NewArticleDistributeMutationVariables = Exact<{
  input: OrderItemDistributeInput;
}>;


export type NewArticleDistributeMutation = { __typename?: 'Mutation', orderItemDistribute: { __typename?: 'ScanResponse', scannedArticle?: { __typename?: 'Article', artNum: number, name: string, count: number, currentPrice: number, size: string, pmLocation: string } | null } };

export type OrderItemDistributeMutationVariables = Exact<{
  in: OrderItemDistributeInput;
}>;


export type OrderItemDistributeMutation = { __typename?: 'Mutation', orderItemDistribute: { __typename?: 'ScanResponse', status: Status, scannedArticle?: { __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string } | null, orderDetails?: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, packageCount: number, targetDate: string, deliveryAddress?: { __typename?: 'Address', id: number, firstName: string, lastName: string, anrede: string, company: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } | null } };

export type OrderItemResetMutationVariables = Exact<{
  in: OrderItemResetInput;
}>;


export type OrderItemResetMutation = { __typename?: 'Mutation', orderItemReset: { __typename?: 'ScanResponse', status: Status, scannedArticle?: { __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string } | null, orderDetails?: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, packageCount: number, targetDate: string, deliveryAddress?: { __typename?: 'Address', id: number, company: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } | null } };

export type PrintDailyQrCodeQueryVariables = Exact<{
  printerID: Scalars['String']['input'];
}>;


export type PrintDailyQrCodeQuery = { __typename?: 'Query', printDailyQRCode: boolean };

export type PrintDeliveryNoteQueryVariables = Exact<{
  in: PrintDeliveryNoteInput;
}>;


export type PrintDeliveryNoteQuery = { __typename?: 'Query', printDeliveryNote: boolean };

export type PrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type PrintersQuery = { __typename?: 'Query', printers: Array<{ __typename?: 'Printer', printerID: number, printerIP: string, printerName: string, printerPort: string }> };

export type ShipLabelMutationVariables = Exact<{
  in: ShiplabelInput;
}>;


export type ShipLabelMutation = { __typename?: 'Mutation', shipLabel: { __typename?: 'ShipLabelResponse', journalId: number, msg: Array<string> } };

export type ShipLabelCancelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ShipLabelCancelMutation = { __typename?: 'Mutation', shipLabelCancel: boolean };

export type SpecialChangesMutationVariables = Exact<{
  in: SpecialChangesInput;
}>;


export type SpecialChangesMutation = { __typename?: 'Mutation', specialChanges: boolean };

export type SupplierCollectionClaimMutationVariables = Exact<{
  in: SupplierCollectionClaimInput;
}>;


export type SupplierCollectionClaimMutation = { __typename?: 'Mutation', supplierCollectionClaim: boolean };

export type SupplierCollectionReleaseMutationVariables = Exact<{
  in?: InputMaybe<SupplierCollectionReleaseInput>;
}>;


export type SupplierCollectionReleaseMutation = { __typename?: 'Mutation', supplierCollectionRelease: boolean };

export type SupplierCollectionsQueryVariables = Exact<{
  filter: SupplierCollectionFilterInput;
}>;


export type SupplierCollectionsQuery = { __typename?: 'Query', supplierCollections: Array<{ __typename?: 'SupplierCollection', supplierName: string, supplierId: number, round: number, count: number, pickupTime: string, pickupDate: string, region?: string | null, driverName?: string | null, isCollected?: boolean | null, collectedAt?: string | null, note?: string | null, address: { __typename?: 'SupplierAddress', street: string, postCode: string, city: string }, container: Array<{ __typename?: 'SupplierContainer', id: number, supplierId: number, name: string, owed: number } | null> }> };

export type SupplierCollectionUpdateMutationVariables = Exact<{
  in?: InputMaybe<SupplierCollectionUpdateInput>;
}>;


export type SupplierCollectionUpdateMutation = { __typename?: 'Mutation', supplierCollectionUpdate: boolean };

export type SupplierContainersUpsertMutationVariables = Exact<{
  in?: InputMaybe<SupplierContainersUpsertInput>;
}>;


export type SupplierContainersUpsertMutation = { __typename?: 'Mutation', supplierContainersUpsert: boolean };

export type SupplierOrderedItemsQueryVariables = Exact<{
  round: Scalars['Int']['input'];
}>;


export type SupplierOrderedItemsQuery = { __typename?: 'Query', supplierOrderedItems: Array<{ __typename?: 'SupplierOrderedItems', RoundListID: number, Orderid: number, Ewid: number, IsTagged: boolean, SupplierID: number, Quantity: number, MainbaseBdb: number, CreatedAt: string, GERNAME: string, BOTNAME: string, Sorte: string, Von: number, Bis: number, Lieferant: string, G24: number, Flavour: number, Is24hDelivery: boolean }> };

export type TagItemMutationVariables = Exact<{
  barcode: Scalars['String']['input'];
}>;


export type TagItemMutation = { __typename?: 'Mutation', tagItem: { __typename?: 'ScanResponse', status: Status, scannedArticle?: { __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string } | null, orderDetails?: { __typename?: 'Order', orderID: number, status: number, sh: number, plantCount: number, itemCount: number, packageCount: number, targetDate: string, deliveryAddress?: { __typename?: 'Address', id: number, firstName: string, lastName: string, anrede: string, company: string, fullName: string, street: string, zipCode: string, city: string, country: string, countryCode: string } | null, items: Array<{ __typename?: 'Article', id: number, artNum: number, name: string, size: string, count: number, supplier: string, tagged: boolean, timeTagged: string, personTagged: string, distributed: boolean, timeDistributed: string, personDistributed: string, isSplitted: boolean, isDeleted: boolean, isPalletArticle: boolean, currentPrice: number, pmLocation: string }> } | null } };

export type UserAchievementQueryVariables = Exact<{
  userID: Scalars['Int']['input'];
}>;


export type UserAchievementQuery = { __typename?: 'Query', userAchievment: { __typename?: 'UserHistory', itemsDistributed: number, itemsLabled: number, packagesDispatched: number, articleHistory: Array<{ __typename?: 'ArticleHistory', taskType: number, orderID: number, round: number, sh: number, quantity: number, articleName: string, from: number, to: number, finishedAt: string }> } };

export type UserSettingSaveMutationVariables = Exact<{
  in: UserSettingSaveInput;
}>;


export type UserSettingSaveMutation = { __typename?: 'Mutation', userSettingSave: { __typename?: 'UserSettingSaveResponse', statusCode: number, message?: string | null } };

export type UserSupplierCollectionsQueryVariables = Exact<{
  filter: UserSupplierCollectionFilterInput;
}>;


export type UserSupplierCollectionsQuery = { __typename?: 'Query', userSupplierCollections: Array<{ __typename?: 'SupplierCollection', supplierName: string, supplierId: number, round: number, count: number, pickupTime: string, pickupDate: string, region?: string | null, driverName?: string | null, isCollected?: boolean | null, collectedAt?: string | null, note?: string | null, address: { __typename?: 'SupplierAddress', street: string, postCode: string, city: string }, container: Array<{ __typename?: 'SupplierContainer', supplierId: number, id: number, name: string, owed: number } | null> }> };
