import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid-pro"
import React, { MouseEventHandler, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import { useLocalStorage } from "utils/useLocalStorage"
import { dataGridDEde } from "utils/dataGridLocale"
import {
  getUserSupplierCollections,
  supplierCollectionRelease,
} from "queries/supplierCollections"
import { SupplierCollection, User } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { ArrowBack, ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { CollapsibleCard } from "component/CollapsibleCard/CollapsibleCard"
import { useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"

const origin = "burstah 13, 25474 ellerbek, germany"
const destination = "burstah 13, 25474 ellerbek, germany"

const MyTourPage = () => {
  const [tour, setTour] = useLocalStorage<SupplierCollection[]>("tour", [])
  const [rows, setRows] = useState<SupplierCollection[]>([])
  const [rowsContainer, setRowsContainer] = useState<any[]>([])
  const userData = useAppSelector(selectUser)
  const navigate = useNavigate()
  const [optimizedWaypoints, setOptimizedWaypoints] = useState<string[]>([])

  const getUserCollections = async () => {
    const collections = await getUserSupplierCollections({
      activeOnly: true,
      userId: userData.user.id,
    })
    if (collections) {
      setRows(collections.userSupplierCollections)
    }
  }

  useEffect(() => {
    getUserCollections()
  }, [])

  useEffect(() => {
    if (rows.length > 0) {
      const containerSummary = calculateContainerSummary(rows)
      setRowsContainer(containerSummary)
    }
  }, [rows])

  const waypoints = rows.map((item) => {
    return (
      item.address.street +
      ", " +
      item.address.postCode +
      ", " +
      item.address.city +
      ", germany"
    )
  })

  useEffect(() => {
    calculateOptimizedRoute()
  }, [rows])

  const calculateOptimizedRoute = () => {
    if (window.google && window.google.maps) {
      const directionsService = new window.google.maps.DirectionsService()
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints.map((waypoint) => ({
            location: waypoint,
            stopover: true,
          })),
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const waypointOrder = result?.routes[0].waypoint_order
            if (waypointOrder)
              setOptimizedWaypoints(
                waypointOrder.map((index: number) => waypoints[index])
              )
            console.log(optimizedWaypoints)
          } else {
            console.error("Fehler beim Berechnen der Route:", status)
          }
        }
      )
    } else {
      console.error("Google Maps API ist nicht geladen.")
    }
  }

  const calculateContainerSummary = (
    supplierCollections: SupplierCollection[]
  ) => {
    const containerSummary: { [key: string]: number } = {}
    supplierCollections.forEach((collection) => {
      collection.container.forEach((container) => {
        if (container) {
          if (containerSummary[container.name]) {
            containerSummary[container.name] += container.owed
          } else {
            containerSummary[container.name] = container.owed
          }
        }
      })
    })
    return Object.entries(containerSummary).map(([name, owed]) => ({
      name,
      owed,
    }))
  }

  const handleSupplierCollectionRelease = async (
    supplierId: number,
    runde: number
  ) => {
    const returnValue = await supplierCollectionRelease({
      supplierID: supplierId,
      runde: runde,
    })
    if (returnValue) {
      enqueueSnackbar("Auftrag erfolgreich entfernt", { variant: "success" })

      if (tour.length > 0) {
        const newRow = rows.filter(
          (row) => !(row.supplierId === supplierId && row.round === runde)
        )
        const newTour = tour.filter(
          (row) => !(row.supplierId === supplierId && row.round === runde)
        )
        setTour(newTour)
        setRows(newRow)
      }
    } else {
      enqueueSnackbar("Auftrag konnte nicht entfernt werden", {
        variant: "error",
      })
    }
  }

  const colsContainer: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    { field: "owed", headerName: "Saldo", width: 100, type: "number" },
  ]

  const cols: GridColDef[] = [
    {
      field: "action",
      type: "actions",
      width: 100,
      getActions: (params: GridRowParams) => {
        const handleUpDownClick =
          (
            direction: number
          ): MouseEventHandler<HTMLButtonElement> | undefined =>
          () => {
            const rowsClone = [...rows]
            const row = rowsClone.splice(rowIndex, 1)[0]
            rowsClone.splice(rowIndex + direction, 0, row)

            const tourClone = [...tour]
            const tourRow = tourClone.splice(rowIndex, 1)[0]
            tourClone.splice(rowIndex + direction, 0, tourRow)

            setTour(tourClone)
            setRows(rowsClone)
          }

        const rowIndex = tour.findIndex(
          (row) =>
            row.supplierId === params.row.supplierId &&
            row.round === params.row.round
        )
        const rowCount = rows.length

        const isFirstRow = rowIndex === 0
        const isLastRow = rowIndex === rowCount - 1

        return [
          <GridActionsCellItem
            icon={<ArrowUpward />}
            label="Up"
            onClick={handleUpDownClick(-1)}
            disabled={isFirstRow}
          />,
          <Divider orientation="vertical" flexItem />,
          <GridActionsCellItem
            icon={<ArrowDownward />}
            label="Down"
            onClick={handleUpDownClick(1)}
            disabled={isLastRow}
          />,
          <Divider orientation="vertical" flexItem />,
        ]
      },
    },
    { field: "supplierId", headerName: "ID", width: 100 },
    { field: "supplierName", headerName: "Lieferant", width: 150 },
    { field: "round", headerName: "Runde", width: 100 },
    {
      field: "city",
      headerName: "Ort",
      width: 250,
      valueGetter: (_value, row) => {
        return `${row.address.postCode || ""} ${row.address.city || ""}`
      },
    },
    { field: "count", headerName: "Anzahl", width: 100 },
    {
      field: "pickupTime",
      headerName: "Abholung ab",
      width: 120,
      valueGetter: (_value, row) => {
        if (row.pickupTime == "") return ""
        return `${row.pickupTime.slice(0, -3) + " Uhr"}`
      },
    },
    {
      field: "note",
      headerName: "Bemerkung",
      width: 200,
      renderCell: (params) => {
        // if (params.value.includes("Bestellung zu")) return ""
        return <Typography variant="caption">{params.value}</Typography>
      },
    },
    {
      field: "actions",

      type: "actions",
      getActions: ({ id, row }) => {
        return [
          <Button
            onClick={() =>
              handleSupplierCollectionRelease(row.supplierId, row.round)
            }
          >
            Abgeben
          </Button>,
        ]
      },
    },
  ]

  const handleReturnClick = () => {
    navigate(AppRoute.DRIVER)
  }

  const handleRoutePlanning = () => {
    const url =
      `https://www.google.com/maps/dir/?api=1` +
      `&origin=${encodeURIComponent(origin)}` +
      `&destination=${encodeURIComponent(destination)}` +
      `&waypoints=${encodeURIComponent(optimizedWaypoints.join("|"))}` +
      `&travelmode=driving`

    window.open(url, "_blank")
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        flexGrow: 1,
        flex: 1,
        padding: 1,
        paddingTop: 2,

        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton onClick={handleReturnClick}>
          <ArrowBack sx={{ fontSize: 30 }} />
        </IconButton>
        <Typography variant="h4">Meine Tour</Typography>
      </Box>

      <CollapsibleCard defaultOpen={true} title="Lieferanten">
        <Box sx={{ height: "75vh", overflow: "auto", pb: 7 }}>
          <DataGridPro
            getRowId={(row) => row.supplierId + "-" + row.round}
            rows={rows}
            columns={cols}
            localeText={dataGridDEde}
            onRowClick={(row) => {
              navigate(`${AppRoute.DRIVER}/${row.id}/${row.row.round}`)
            }}
            disableColumnMenu
            disableColumnSorting
            getRowHeight={() => "auto"}
          />
        </Box>
      </CollapsibleCard>

      <Box sx={{ mb: "auto" }}>
        <CollapsibleCard defaultOpen={false} title="Container">
          <Box sx={{ height: "50vh", overflow: "auto" }}>
            <DataGridPro
              getRowId={(row) => row.name}
              rows={rowsContainer}
              columns={colsContainer}
              localeText={dataGridDEde}
              onRowClick={(row) => navigate(`${AppRoute.DRIVER}/${row.id}`)}
              disableColumnMenu
              density="compact"
            />
          </Box>
        </CollapsibleCard>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "50%" }}
          onClick={handleRoutePlanning}
        >
          zu Google Maps
        </Button>
      </Box>
    </Box>
  )
}

export default MyTourPage
