//Icons and animations
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import { selectLibras } from "app/libraListSlice"
import { selectPrinters } from "app/printerSlice"
import { selectSettings } from "app/settingSlice"
import ArticleListShipment from "component/ArticleListShipment"
import CustomInput from "component/CustomInput"
import {
  Carrier,
  Order,
  PrintDeliveryNoteQueryVariables,
  ShipLabelCancelMutationVariables,
  ShipLabelMutationVariables,
} from "generated/graphql"
import Lottie from "lottie-react"
import { enqueueSnackbar } from "notistack"
import { getArticleStatus } from "queries/articleStatus"
import { printDeliveryNote } from "queries/printDeliveryNote"
import { shipLabel } from "queries/shipLabel"
import { shipLabelCancel } from "queries/shipLabelCancel"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { cartonEAN } from "utils/constants"
import { isChar } from "utils/isChar"
import { removeFirstChar } from "utils/removeFirstChar"
import { AppRoute } from "utils/routes"
import loadingCircle from "../animations/loading_circle.json"
import loadingFail from "../animations/loading_fail.json"
import loadingSuccess from "../animations/loading_success.json"
import { fetchIsOrderShipable } from "queries/IsOrderShipable"

const carrierIcons: { icon: string; carrier: Carrier }[] = [
  { icon: "ic_dpd.png", carrier: Carrier.Dpd },
  { icon: "ic_gls.png", carrier: Carrier.Gls },
  { icon: "ic_gls_express.png", carrier: Carrier.GlsExpress },
  { icon: "ic_gls_letterbox.png", carrier: Carrier.GlsLetterbox },
  { icon: "dhl.png", carrier: Carrier.Dhl },
  { icon: "dhl_warenpost.jpg", carrier: Carrier.DhlWarenpost },
]

const ShipmentPage = (): JSX.Element => {
  const navigate = useNavigate()
  const settings = useAppSelector(selectSettings)
  const printers = useAppSelector(selectPrinters)
  const libras = useAppSelector(selectLibras)
  const selectedPrinter = printers.find(
    (p) => p.printerID.toString() === settings.selectedPrinterID
  )
  const selectedLibra = libras.find(
    (l) => l.id.toString() === settings.selectedLibraID
  )
  const [shipmentID, setShipmentID] = useState<number>(-1)
  const [barcode, setBarcode] = useState<string>("")
  const [statusMessage, setStatusMessage] = useState("")
  const [articleDetails, setArticleDetails] = useState<Order | null>(null)
  const [previousCarrier, setPreviousCarrier] = useState<Carrier>(Carrier.Dpd)
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] =
    useState<boolean>(false)
  const [confirmationDialogMessage, setConfirmationDialogMessage] =
    useState<string>("")
  const [shipmentStatus, setShipmentStatus] = useState<
    "loading" | "success" | "failed" | null
  >(null)
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  const [background, setBackground] = useState<string>("primary.main")
  const [carton, setCarton] = useState<string>("")
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    //NOTE: return to sender if reload
    if (printers.length === 0) {
      navigate(AppRoute.NAVIGATION)
      return
    }
    if (libras.length === 0) {
      navigate(AppRoute.NAVIGATION)
      return
    }
    if (!selectedPrinter) {
      enqueueSnackbar(t("printerNotSelected"), { variant: "warning" })
      navigate(AppRoute.NAVIGATION)
      return
    }
    if (!selectedLibra) {
      enqueueSnackbar(t("libraNotSelected"), { variant: "warning" })
      navigate(AppRoute.NAVIGATION)
      return
    }
  }, [printers, libras])

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [])

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const getAnimationDataBasedOnStatus = (status: string | null) => {
    switch (status) {
      case "loading":
        return loadingCircle
      case "success":
        return loadingSuccess
      case "failed":
        return loadingFail
      default:
        return null
    }
  }

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined
    if (shipmentStatus === "success" || shipmentStatus === "failed") {
      timeoutId = setTimeout(() => {
        setShipmentStatus(null)
        setShowAnimation(false)
      }, 1500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [shipmentStatus])

  const canShipOrder = (articleDetails: Order | null): boolean => {
    if (!articleDetails) {
      return false
    }
    for (const item of articleDetails.items) {
      if ((!item.tagged || !item.distributed) && !item.isSplitted) {
        return false
      }
    }
    return true
  }

  const handleShipLabel = async (carrier: Carrier, skipCheck: boolean) => {
    if (articleDetails) {
      if (canShipOrder(articleDetails) === false) {
        enqueueSnackbar(t("shipmentNotPossible"), { variant: "warning" })
        return
      }
      if (skipCheck === false) {
        // check backend for any reason why the shipment can't be created
        const checkRes = await fetchIsOrderShipable(articleDetails.orderID)
        // now we show a confirmation dialog if there is a reason which needs to be confirmed
        if (!checkRes) {
          return
        }
        if (checkRes.isOrderShipable.msg.length > 0) {
          // we need to confirm the shipment one more time
          setConfirmationDialogIsOpen(true)
          setPreviousCarrier(carrier)
          setConfirmationDialogMessage(checkRes.isOrderShipable.msg)
          return
        }
      }
      setShowAnimation(true)
      setShipmentStatus("loading")
      const shipLabelVariables: ShipLabelMutationVariables = {
        in: {
          carrier: carrier,
          orderID: articleDetails.orderID.toString(),
          cartonBarcode: carton,
          LibraIpPort: `${selectedLibra?.ip}:${selectedLibra?.port}`,
          PrinterIpPort: `${selectedPrinter?.printerIP}:${selectedPrinter?.printerPort}`,
        },
      }
      const result = await shipLabel(shipLabelVariables)
      if (result && result.shipLabel.msg.length > 0) {
        const msg = result.shipLabel.msg.join(", ")
        enqueueSnackbar(msg, { variant: "warning" })
      }
      if (result && result.shipLabel.journalId !== 0) {
        setShipmentID(result.shipLabel.journalId)
        setShipmentStatus("success")
        setStatusMessage(t("shipmentCreated"))
        enqueueSnackbar(t("shipmentCreated"), { variant: "success" })
        setCarton("")
      } else {
        setShipmentStatus("failed")
      }
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus()
      }
    }
  }

  const fetchArticleDetails = async (barcode: string) => {
    if (
      barcode[0].toLowerCase() == "k" ||
      cartonEAN.includes(barcode.toLocaleLowerCase())
    ) {
      if (settings.isCartonEnabled) {
        setCarton(barcode)
        enqueueSnackbar(t("cartonIsSet"), { variant: "success" })
      } else {
        //enqueueSnackbar(t("cartonIsDisabled"), { variant: "info" })
      }
      return
    } else {
      // check if barcode starts with a letter
      // if so, remove it (e.g. "A123456789" -> "123456789")
      if (isChar(barcode[0])) {
        barcode = removeFirstChar(barcode)
      }
      // reset old data
      setArticleDetails(null)
      const res = await getArticleStatus(barcode)

      if (res?.articleStatus && res.articleStatus.orderID !== 0) {
        setArticleDetails(res.articleStatus)
        const allTaggedAndDistributed = res.articleStatus.items.every(
          (item) => item.tagged && item.distributed
        )
        if (allTaggedAndDistributed) {
          setStatusMessage(t("boxDone"))
          setBackground("primary.main")
        } else {
          setStatusMessage(t("OK"))
          setBackground("primary.main")
        }
      } else {
        setStatusMessage(t("barcodeError"))
        enqueueSnackbar(t("barcodeError"), { variant: "warning" })
        setBackground("error.main")
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      setShipmentID(-1)
      fetchArticleDetails(barcode).finally(() => {
        setBarcode("")
      })
    }
  }

  const handleCancelShipment = async () => {
    setShowAnimation(true)
    setShipmentStatus("loading")
    const shipLabelCancelVariables: ShipLabelCancelMutationVariables = {
      id: shipmentID.toString(),
    }
    const result = await shipLabelCancel(shipLabelCancelVariables)
    if (result) {
      setShipmentID(-1)
      setShipmentStatus("success")
      setStatusMessage(t("OK"))
      enqueueSnackbar(t("shipmentCanceld"), { variant: "success" })
    }
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }

  const handleDeliveryNote = async () => {
    if (articleDetails && selectedPrinter) {
      const deliveryNoteVariables: PrintDeliveryNoteQueryVariables = {
        in: {
          orderID: articleDetails.orderID,
          printerIpPort: `${selectedPrinter.printerIP}:${selectedPrinter.printerPort}`,
        },
      }
      const result = await printDeliveryNote(deliveryNoteVariables)
      if (result) {
        enqueueSnackbar(t("deliveryNotePrinted"), { variant: "success" })
      } else {
        enqueueSnackbar(t("deliveryNoteAlreadyPrinted"), { variant: "warning" })
      }
    }
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        paddingTop: "1%",
      }}
    >
      {showAnimation && (
        <Box
          sx={{
            backgroundColor: "white",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ width: "100%", height: "100%" }}
            animationData={getAnimationDataBasedOnStatus(shipmentStatus)}
          />
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(AppRoute.NAVIGATION)
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ alignSelf: "center" }}>
              {" "}
              {t("ship")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CustomInput
                data-cy="ShipBarcodeInput"
                sx={{ width: "8rem" }}
                id="barcode"
                placeholder="Barcode"
                inputRef={barcodeInputRef}
                value={barcode}
                onChange={handleBarcodeInput}
                onKeyDown={handleKeyDown}
              />
            </Box>
          </Box>
          {articleDetails && (
            <Card
              sx={{ width: "100%", justifyContent: "center", marginY: "8px" }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  color:
                    statusMessage === t("barcodeError")
                      ? "error.main"
                      : "black",
                }}
                variant="body1"
              >
                {statusMessage}
              </Typography>
            </Card>
          )}
        </Box>

        {articleDetails && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  marginY: "8px",
                }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("orderID")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.orderID}
                </Typography>
              </Card>
              <Card
                sx={{ width: "100%", justifyContent: "center", margin: "8px" }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("status")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.status}
                </Typography>
              </Card>
              <Card
                sx={{ width: "100%", justifyContent: "center", marginY: "8px" }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("SH")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.sh}
                </Typography>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Card
                sx={{ width: "100%", justifyContent: "center", marginY: "8px" }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("packages")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.packageCount}
                </Typography>
              </Card>
              <Card
                sx={{ width: "100%", justifyContent: "center", margin: "8px" }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("totalDifferentPlantCount")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.plantCount}
                </Typography>
              </Card>
              <Card
                sx={{ width: "100%", justifyContent: "center", marginY: "8px" }}
              >
                <Typography sx={{ textAlign: "center" }} variant="body2">
                  {t("totalItemCount")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {articleDetails.itemCount}
                </Typography>
              </Card>
            </Box>
          </Box>
        )}
        {articleDetails && (
          <ArticleListShipment
            articlesDetails={articleDetails}
            maxHeight={300}
            setTagRes={setArticleDetails}
            setCheckedItems={() => {}}
          />
        )}
        {articleDetails && shipmentID === -1 && (
          <>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-end",
                padding: "8px",
              }}
            >
              <Button
                data-cy="DeliveryNoteButton"
                sx={{
                  bgcolor: "primary.dark",
                }}
                variant="contained"
                onClick={() => {
                  handleDeliveryNote()
                }}
              >
                {t("deliveryNote")}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              {carrierIcons.map((item, idx) => (
                <IconButton
                  data-cy={item.carrier}
                  key={idx}
                  onClick={() => handleShipLabel(item.carrier, false)}
                  sx={{
                    flex: "1",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: idx === 0 ? "0px" : "4px",
                    marginRight: idx === 5 ? "0px" : "4px",
                    boxSizing: "border-box",
                    backgroundColor: "white",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/${item.icon}`}
                    alt={`Icon ${idx + 1}`}
                    style={{ width: "80%", height: "auto" }}
                  />
                </IconButton>
              ))}
            </Box>
          </>
        )}
        {shipmentID > 0 && (
          <Button
            data-cy="CancelShipmentButton"
            sx={{ marginTop: "8px" }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              handleCancelShipment()
            }}
          >
            {t("cancelShipment")}
          </Button>
        )}
      </Box>
      <Dialog
        open={confirmationDialogIsOpen}
        onClose={() => setConfirmationDialogIsOpen(false)}
      >
        <DialogTitle>{t("shipmentConfirmation")}</DialogTitle>
        <DialogContent>
          <Typography sx={{ padding: "8px" }}>
            {confirmationDialogMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={() => {
              setConfirmationDialogIsOpen(false)
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => {
              setConfirmationDialogIsOpen(false)
              handleShipLabel(previousCarrier, true)
            }}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ShipmentPage
