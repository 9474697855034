import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "utils/dataGridLocale"
import {
  getSupplierCollections,
  supplierCollectionClaim,
} from "queries/supplierCollections"
import { SupplierCollection, User } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { useLocalStorage } from "utils/useLocalStorage"
import { ArrowBack } from "@mui/icons-material"

export type TourOrder = {
  supplierId: number
  round: number
}

const DriverPage = () => {
  const [user, setUser] = useLocalStorage<User>("user", {
    id: 0,
    language: "",
    privileges: 0,
    username: "",
  })
  const [tour, setTour] = useLocalStorage<TourOrder[]>("tour", [])
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs())
  // const [selectedTour, setSelectedTour] = useState<string>("")
  const [rows, setRows] = useState<SupplierCollection[]>([])
  // const [regions, setRegions] = useState<string[]>([])
  const navigate = useNavigate()

  const getCollections = async () => {
    const collections = await getSupplierCollections({ activeOnly: true })
    if (collections && selectedDate) {
      const newRow = collections.supplierCollections.filter(
        (item) =>
          dayjs(item.pickupDate) <= selectedDate &&
          !item.isCollected &&
          item.driverName === null
      )
      setRows(newRow)
      // setRegions(["Alle"])
    }
  }

  useEffect(() => {
    getCollections()
  }, [selectedDate])

  const handleDetailClick = (id: string, round: number) => {
    navigate(`${AppRoute.DRIVER}/${id}/${round}`)
  }

  const handleClaimCollection = async (supplierId: number, round: number) => {
    const returnValue = await supplierCollectionClaim({
      userID: user.id,
      supplierID: supplierId,
      runde: round,
    })
    if (returnValue) {
      enqueueSnackbar("Auftrag erfolgreich hinzugefügt", { variant: "success" })
      const newRows = rows.filter((row) => row.supplierId !== supplierId)
      setRows(newRows)

      const newTourStop = rows.find((row) => row.supplierId === supplierId)
      if (newTourStop) {
        const newTourOrder: TourOrder[] = [...tour, { supplierId, round }]
        setTour(newTourOrder)
      }
    } else {
      enqueueSnackbar("Auftrag konnte nicht hinzugefügt werden", {
        variant: "error",
      })
    }
  }

  const cols: GridColDef[] = [
    { field: "supplierName", headerName: "Lieferant", width: 150 },
    { field: "round", headerName: "Runde", width: 100 },
    {
      field: "city",
      headerName: "Ort",
      width: 250,
      valueGetter: (value, row) => {
        return `${row.address.postCode || ""} ${row.address.city || ""}`
      },
    },
    { field: "count", headerName: "Anzahl", width: 100 },
    {
      field: "pickupTime",
      headerName: "Abholung ab",
      width: 130,
      valueGetter: (_value, row) => {
        if (row.pickupTime == "") return ""
        return `${row.pickupTime.slice(0, -3) + " Uhr"}`
      },
    },
    {
      field: "note",
      headerName: "Bemerkung",
      width: 200,
      renderCell: (params) => {
        // if (params.value.includes("Bestellung zu")) return ""
        return <Typography variant="caption">{params.value}</Typography>
      },
    },
    {
      field: "actions",
      headerName: "Details",
      type: "actions",
      getActions: ({ row }) => {
        return [
          <Button
            onClick={() => handleClaimCollection(row.supplierId, row.round)}
          >
            Auswählen
          </Button>,
        ]
      },
    },
  ]

  const handleMyTourClick = () => {
    navigate(AppRoute.DRIVER_TOUR)
  }

  // const handleChangeRegion = (event: SelectChangeEvent) => {
  //   if (event.target.value === "Alle") {
  //     getCollections()
  //   } else {
  //     const newRows = rows.filter((row) => row.region === event.target.value)
  //     setRows(newRows)
  //   }
  // }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        flexGrow: 1,
        flex: 1,

        padding: 1,
        paddingTop: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton>
            <ArrowBack
              sx={{ fontSize: 30 }}
              onClick={() => {
                navigate(AppRoute.NAVIGATION)
              }}
            />
          </IconButton>
          <Typography variant="h4">Abholungen</Typography>
        </Box>

        <DatePicker
          label="Datum"
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          sx={{ width: "50%" }}
        />

        {/* <FormControl fullWidth>
          <InputLabel id="touren">Region</InputLabel>
          <Select
            labelId="touren"
            id="touren"
            value={selectedTour}
            label="Region"
            onChange={handleChangeRegion}
          >
            {regions.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Box>

      {tour.length > 0 && (
        <Button fullWidth variant="contained" onClick={handleMyTourClick}>
          Meine Tour
        </Button>
      )}
      <Box sx={{ m: 2, height: "800px" }}>
        <DataGridPro
          getRowId={(row) => row.supplierId + "-" + row.round}
          rows={rows}
          columns={cols}
          localeText={dataGridDEde}
          onRowClick={(params) =>
            handleDetailClick(params.row.supplierId, params.row.round)
          }
          disableColumnMenu
          disableRowSelectionOnClick
          sx={{ height: "100%" }}
          getRowHeight={() => "auto"}
        />
      </Box>
    </Box>
  )
}

export default DriverPage
